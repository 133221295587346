import {
  CreateButton,
  Datagrid,
  TextField,
  TopToolbar,
  ExportButton,
} from 'react-admin';
import { ImportButton } from 'react-admin-import-csv';
import ListA from '../Components/ListA';
import TextFieldA from '../Components/TextFieldA';

const ListActions = (props: any) => {
  const { className, basePath } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <ExportButton basePath={basePath} />
      <ImportButton {...props} />
    </TopToolbar>
  );
};
const list = (props: any) => {
  return (
    <ListA {...props} actions={<ListActions />}>
      <Datagrid
        rowClick={(data: any) => {
          window.open(`/#/admin-admin-user/${data}`, '_blank');
          return '';
        }}
      >
        <TextFieldA source="id" />
        <TextFieldA label="UserName" source="userName" />
      </Datagrid>
    </ListA>
  );
};

export default list;
