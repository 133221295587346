import {
  Create,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import TextInputA from '../Components/TextInputA';

const create = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInputA resettable source="firstName" />
      <TextInputA resettable source="lastName" />
      <TextInputA resettable source="userName" />
      <TextInputA resettable source="email" />
      <TextInputA resettable source="phoneNumber" />
      <TextInputA resettable source="passwordHash" />
    </SimpleForm>
  </Create>
);

export default create;
