import React from 'react';
import TextFieldA from '../Components/TextFieldA';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import CustomDataGrid from '../Components/CustomDataGrid';

const list = (props: any) => {

  return (
    <ListA {...props}>
      <CustomDataGrid>
        <TextFieldA label='Build Version' source="version" />
        <TextFieldA label="URL of Version" source="url" />
        <DateFieldA source="createdAt" />
        <DateFieldA source="updatedAt" />
        <TextFieldA source="id" />
      </CustomDataGrid>
    </ListA>
  );
};

export default list;
