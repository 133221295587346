import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from 'react-admin';
import { defaultApiService } from '../Utils/api';

export default async (type: any, params: any) => {
  try {
    if (type === AUTH_LOGIN) {
      const { username, password } = params;
      const resp = await defaultApiService.gfAdminUserControllerSignIn({
        userName: username,
        password,
      });
      localStorage.setItem('accessToken', resp.token);
      return Promise.resolve();
    } else if (type === AUTH_LOGOUT) {
      localStorage.removeItem('accessToken');
      return Promise.resolve();
    } else if (type === AUTH_CHECK) {
      const accessToken = localStorage.getItem('accessToken') as string;
      if (accessToken) {
        const result = await defaultApiService.gfAdminUserControllerVerifyToken(
          accessToken,
        );

        if (result) {
          return Promise.resolve();
        }
      } else {
        return Promise.reject();
      }
    } else if (type === AUTH_GET_PERMISSIONS) {
      return Promise.resolve();
    }
  } catch (error) {
    return Promise.resolve();
  }
};
