import {
  DateTimeInput,
  Edit,
  required,
  SimpleForm,
  useMutation,
  useRedirect,
} from 'react-admin';
import React, { useCallback, useState } from 'react';
import TextInputA from '../Components/TextInputA';

const EditFlyer = (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutate] = useMutation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const redirect = useRedirect();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const editFlyer = useCallback(
    async values => {
      try {
        await mutate(
          {
            type: 'update',
            resource: 'admin-version-control',
            payload: { id: values.id, data: values },
          },
          { returnPromise: true },
        );
        redirect('list', props.basePath);
      } catch (error) {
        console.log('storesData error', error);
      }
    },
    [mutate],
  );

  return (
    <div>
      <Edit {...props} undoable={false}>
        <SimpleForm redirect="list" save={editFlyer}>
          <TextInputA disabled source="id" />
          <DateTimeInput disabled source="createdAt" />
          <DateTimeInput disabled source="updatedAt" />

          <TextInputA
            disabled={true}
            helperText="Put anything that you can recognize"
            source="version"
            label="Version of Build"
            validate={required()}
            resettable
            fullWidth
          />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default EditFlyer;
