import { TextInput } from 'react-admin';

const TextInputA = (props: any) => {
  return (
    <TextInput
      data-testid={`${props.resource} ${props.source}`}
      fullWidth={true}
      {...props}
    />
  );
};

export default TextInputA;
