import { Datagrid, TextField } from 'react-admin';
import CustomDataGrid from '../Components/CustomDataGrid';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import TextFieldA from '../Components/TextFieldA';

const list = (props: any) => {
  return (
    <ListA {...props}>
      <CustomDataGrid>
        <TextFieldA source="title" />
        <TextFieldA source="body" />
        <DateFieldA source="createdAt" />
        <DateFieldA source="updatedAt" />
        <TextFieldA source="id" />
      </CustomDataGrid>
    </ListA>
  );
};

export default list;
