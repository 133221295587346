import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Button } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
interface Props {
  handleTrigger: any;
  buttonName: string;
  condition: string;
}
export const TriggerButton = ({
  handleTrigger,
  buttonName,
  condition,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState('default');
  const [message, setMessage] = useState('');

  const handleClick = async () => {
    setMessage(condition);
    setIsLoading(true);
    try {
      const response = await handleTrigger(condition);
      if (response === 'error') {
        setSuccess('error');
      } else {
        setSuccess('success');
      }
    } catch {
      setSuccess('error');
    }

    setIsLoading(false);
    setTimeout(() => {
      setSuccess('default');
      setMessage('');
    }, 3000);
  };
  return (
    <>
      <div className="ElasticAlert">
        {success === 'error' ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {message} Clear cache was <strong>UnSuccessful</strong>
          </Alert>
        ) : (
          <></>
        )}
        {success === 'success' ? (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {message} Clear cache was <strong>Successful</strong>
          </Alert>
        ) : (
          <></>
        )}
      </div>
      <Button
        onClick={handleClick}
        variant="contained"
        startIcon={
          isLoading ? <CircularProgress size={20} /> : <RestartAltIcon />
        }
        disabled={isLoading}
        style={{
          marginBottom: '10px',
          maxWidth: '300px',
          textTransform: 'capitalize',
          fontSize: '16px',
        }}
      >
        {buttonName}
      </Button>
    </>
  );
};
