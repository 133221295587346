import { ArrayInput, FormDataConsumer, SimpleFormIterator } from 'react-admin';
import AutocompleteInputA from './AutocompleteInputA';
import { OptionRenderer } from './OptionRenderer';
import ReferenceInputA from './ReferenceInputA';
import RenderImage from './RenderImage';

interface Props {
  inputText: (choice: any) => string;
  imageValidate: any;
}
export const ArrayInputGfImages = ({ inputText, imageValidate }: Props) => {
  return (
    <ArrayInput source="gfImages">
      <SimpleFormIterator>
        <ReferenceInputA
          fullWidth
          filterKey="alt"
          label="Image"
          source="id"
          reference="admin-image"
        >
          <AutocompleteInputA
            fullWidth
            resettable
            matchSuggestion={(_filterValue: any) => true}
            optionText={<OptionRenderer />}
            inputText={inputText}
            validate={imageValidate}
          />
        </ReferenceInputA>
        <FormDataConsumer>
          {({ scopedFormData, ...rest }) => {
            return (
              <>
                <div>
                  <RenderImage Id={scopedFormData?.id} data={rest} />
                </div>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
