import { ReferenceArrayInput } from 'react-admin';
const filterToQuery = (q: string, filterKey: string) => {
  if (q === '' || filterKey === undefined) {
    return {};
  }
  const filter: any = {};
  filter[`${filterKey}`] = q;
  return filter;
};
const ReferenceArrayInputA = (props: any) => (
  <ReferenceArrayInput
    sort={{ field: 'updatedAt', order: 'DESC' }}
    filterToQuery={(q: string) => {
      return filterToQuery(q, props.filterKey);
    }}
    perPage={25}
    {...props}
  />
);

export default ReferenceArrayInputA;
