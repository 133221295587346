import {
  Datagrid,
  DateField,
  EmailField,
  List,
  NumberField,
  TextField,
} from 'react-admin';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import TextFieldA from '../Components/TextFieldA';

const list = (props: any) => {
  return (
    <ListA {...props}>
      <Datagrid rowClick="edit">
        <TextFieldA source="firstName" />
        <TextFieldA source="lastName" />
        <TextFieldA source="userName" />
        <EmailField source="email" />
        <TextFieldA source="phoneNumber" />
        <TextFieldA source="passwordHash" />
        <TextFieldA source="notificationToken" />
        <DateFieldA showTime source="updatedAt" />
        <DateFieldA showTime source="createdAt" />
        <TextFieldA source="firebaseAuthResult" />
        <TextFieldA source="passwordHash" />
        <TextFieldA source="id" />
      </Datagrid>
    </ListA>
  );
};

export default list;
