import { DateTimeInput, Edit, SimpleForm } from 'react-admin';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import NumberInputA from '../Components/NumberInputA';
import ReferenceInputA from '../Components/ReferenceInputA';
import TextInputA from '../Components/TextInputA';
import { storeOptionRenderer } from '../Utils/utils';

const edit = (props: any) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="list">
      <TextInputA resettable disabled source="id" />
      <DateTimeInput disabled showTime source="createdAt" />
      <DateTimeInput disabled showTime source="updatedAt" />
      <NumberInputA source="price" />
      <ReferenceInputA
        filterKey="name"
        label="Product"
        source="product.id"
        reference="admin-product"
      >
        <AutocompleteInputA resettable optionText="name" />
      </ReferenceInputA>
      <ReferenceInputA
        filterKey="merchant.name"
        perPage={9999}
        label="Store"
        sort={{ field: 'merchant.name', order: 'ASC' }}
        source="store.id"
        reference="admin-store"
      >
        <AutocompleteInputA resettable optionText={storeOptionRenderer} />
      </ReferenceInputA>
    </SimpleForm>
  </Edit>
);

export default edit;
