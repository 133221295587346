import {
  ChipField,
  ImageField,
  ReferenceManyField,
  SearchInput,
  SingleFieldList,
} from 'react-admin';
import CustomDataGrid from '../Components/CustomDataGrid';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import TextFieldA from '../Components/TextFieldA';

const list = (props: any) => {
  const postFilters = [<SearchInput source="q" alwaysOn />];

  return (
    <ListA {...props} filters={postFilters}>
      <CustomDataGrid>
        {/* <ReferenceManyField
          label="Tag"
          reference="admin-product-tag"
          target="products.id"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceManyField> */}
        <TextFieldA source="name" />
        <TextFieldA source="nameChinese" />
        <ImageField
          label="Product Image"
          source="gfImages[0].src"
          sortable={false}
          title="alt"
        />
        {/* <BooleanField source="available" /> */}
        {/* <NumberField source="compareAtPrice" /> */}
        <TextFieldA source="description" />
        <TextFieldA source="descriptionChinese" />
        <TextFieldA
          label="Promotional Price 特价"
          source="gfPlacements[0].gfPromotions[0].price"
        />
        <TextFieldA label="Store Price 原价" source="gfPlacements[0].price" />
        <DateFieldA
          label="Start Date"
          source="gfPlacements[0].gfPromotions[0].validStartDate"
        />
        <DateFieldA
          label="End Date"
          source="gfPlacements[0].gfPromotions[0].validEndDate"
        />
        <TextFieldA
          label="Unit"
          source="gfPlacements[0].gfPromotions[0].unit"
        />
        <DateFieldA showTime source="createdAt" />
        {/* <DateFieldA source="publishedAt" /> */}
        <DateFieldA showTime source="updatedAt" />
        <TextFieldA label="Product Id" source="id" />
        <TextFieldA
          label="Promotion Id"
          source="gfPlacements[0].gfPromotions[0].id"
        />
      </CustomDataGrid>
    </ListA>
  );
};

export default list;
