/**
 * This service implements functionality to make api calls through open api generated client
 * We are adding custom axios instance which helps customize the generated client with interceptors and more axios functionalities
 */
import { AdminApi, DefaultApi } from '@swagger/typescript-fetch-goflyer';
import {
  DefaultApi as SharpDefaultApi,
  Configuration,
} from '@swagger/typescript-fetch-sharp-processor';
// Configuration and base path are not provided

const sharpConfig = new Configuration({
  basePath: window.config.SHARP_SERVER_URL,
});

const sharpApi = new SharpDefaultApi(sharpConfig);

const adminApiService = new AdminApi(
  undefined,
  window.config.REACT_APP_GOFLYER_API_END_POINT,
);

const notificationApiService = new AdminApi(
  undefined,
  window.config.REACT_APP_NOTIFICATION_API_END_POINT,
);

const defaultApiService = new DefaultApi(
  undefined,
  window.config.REACT_APP_GOFLYER_API_END_POINT,
);
export { adminApiService, defaultApiService, sharpApi, notificationApiService };
