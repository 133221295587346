import {
  DateTimeInput,
  Edit,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import NumberInputA from '../Components/NumberInputA';

const edit = (props: any) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="list">
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required()} />
      <TextInput source="nameChinese" validate={required()} />
      <NumberInputA source="index" validate={required()} />
      <DateTimeInput disabled showTime source="createdAt" />
      <DateTimeInput disabled showTime source="updatedAt" />
    </SimpleForm>
  </Edit>
);

export default edit;
