import { NumberInput } from 'react-admin';

const NumberInputA = (props: any) => (
  <NumberInput
    onWheel={(e: any) => e.target.blur()}
    fullWidth={true}
    {...props}
  />
);

export default NumberInputA;
