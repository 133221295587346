import { Box, Pagination, TablePagination } from '@mui/material';
import { List } from 'react-admin';
const ListPagination = (props: any) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    props.setPage(value);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    props.setPerPage(parseInt(event.target.value));
    props.setPage(1);
  };

  return (
    <Box sx={{ p: 2, alignItems: 'center', display: 'flex' }}>
      <TablePagination
        component="div"
        count={props.total === null ? 0 : props.total}
        page={props.total === null ? 0 : props.page}
        onPageChange={() => {}}
        rowsPerPage={props.perPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100, 200, 300, 400, 500, 9999]}
      />
      <Pagination
        className="PaginationReactAdmin"
        count={
          props.total === null ? 1 : Math.round(props.total / props.perPage)
        }
        page={props.total === null ? 0 : props.page}
        onChange={handleChange}
        siblingCount={5}
      />
    </Box>
  );
};

const ListA = (props: any) => (
  <List
    pagination={<ListPagination />}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    {...props}
  />
);

export default ListA;
