import {
  ArrayInput,
  BooleanInput,
  DateTimeInput,
  Edit,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useMutation,
  useNotify,
  useRedirect,
} from 'react-admin';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import ReferenceInputA from '../Components/ReferenceInputA';
import { parseImageToGfImages, storeOptionRenderer } from '../Utils/utils';
import TextInputA from '../Components/TextInputA';
import NumberInputA from '../Components/NumberInputA';
import React, { useEffect, useState } from 'react';
import { adminApiService } from '../Utils/api';
import { GfPromotionDto } from '@swagger/typescript-fetch-goflyer';
import { ArrayInputGfImages } from '../Components/ArrayInputGfImages';

const transform = (data: any) => {
  return {
    ...data,
    gfImages: parseImageToGfImages(data?.gfImages),
  };
};

const inputText = (choice: any) => `${choice.alt} ${choice.src}`;

const EditProduct = (props: any) => {
  const notify = useNotify();
  const redirectTo = useRedirect();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutate] = useMutation();

  const [promotionDetails, setPromotionDetails] = useState<GfPromotionDto>();

  const savePromotion = React.useCallback(
    async values => {
      try {
        const { promotion, ...others } = transform(values);

        await mutate(
          {
            type: 'update',
            resource: 'admin-product',
            payload: {
              id: values.id,
              data: others,
            },
          },
          {
            returnPromise: true,
            onSuccess: async (a: any) => {
              adminApiService
                .updateOneBaseGfPlacementAdminControllerGfPlacement(
                  {
                    price: others.gfPlacements[0].price,
                    store: others.gfPlacements[0].store.id,
                  },
                  others.gfPlacements[0].id as string,
                )
                .then(() => {
                  const promotionObj = {
                    ...promotion,
                    placement: { id: others.gfPlacements[0].id },
                  };

                  adminApiService
                    .updateOneBaseGfPromotionAdminControllerGfPromotion(
                      promotionObj,
                      promotion.id,
                    )
                    .then(() => {
                      redirectTo(props.basePath);
                      notify(`Product, Placement and promotion have updated`);
                    })
                    .catch(() => {});
                })
                .catch(() => {});
            },
          },
        );
      } catch (error) {}
    },
    [mutate],
  );

  const getPromotionDetails = async (productId: string) => {
    try {
      const foundProduct =
        await adminApiService.gfPromotionAdminControllerFindOneByProductId(
          productId,
        );
      const result: GfPromotionDto = await foundProduct.json();

      setPromotionDetails(result as GfPromotionDto);
    } catch {}
  };
  const validate = (props: any) => {};
  const imageValidate = [required(), validate];
  useEffect(() => {
    if (props.id) {
      getPromotionDetails(props.id);
    }
  }, []);

  return (
    <Edit {...props} transform={transform} mutationMode="pessimistic">
      <SimpleForm redirect="list" save={savePromotion}>
        <div style={{ display: 'flex', width: '100%' }}>
          <TextInputA disabled resettable source="id" />
          <TextInputA
            disabled
            resettable
            source="promotion.id"
            defaultValue={promotionDetails?.id}
          />
        </div>

        <BooleanInput source="available" />
        <TextInputA fullWidth resettable source="name" validate={required()} />
        <TextInputA fullWidth resettable source="nameChinese" />
        <TextInputA fullWidth resettable source="nameFrench" />
        <NumberInputA
          fullWidth
          label="Store Price"
          source="gfPlacements[0].price"
          helperText="产品的原价。就是网站，被划掉的那个价格"
          validate={required()}
        />
        <NumberInputA
          fullWidth
          source="promotion.price"
          label="Sale(Promotional) Price"
          validate={required()}
          defaultValue={promotionDetails?.price}
        />
        <NumberInputA
          fullWidth
          source="promotion.quantity"
          label="quantity"
          // validate={required()}
          defaultValue={promotionDetails?.quantity}
        />
        <TextInput
          fullWidth
          source="unit"
          label="unit"
          defaultValue={promotionDetails?.unit}
        />

        <NumberInputA
          fullWidth
          source="promotion.limitQuantityPerFamily"
          label="limitQuantityPerFamily"
          defaultValue={promotionDetails?.limitQuantityPerFamily}
        />

        <TextInputA
          fullWidth
          resettable
          source="description"
          validate={required()}
        />
        <TextInputA
          fullWidth
          resettable
          source="descriptionChinese"
          validate={required()}
        />
        <ReferenceInputA
          fullWidth
          validate={required()}
          filterKey="merchant.name"
          perPage={40}
          label="Store"
          sort={{ field: 'merchant.name', order: 'ASC' }}
          source="gfPlacements[0].store.id"
          reference="admin-store"
          defaultValue={promotionDetails?.placement?.store?.id}
        >
          <AutocompleteInputA
            resettable
            optionText={storeOptionRenderer}
            matchSuggestion={(_filterValue: any) => true}
          />
        </ReferenceInputA>

        <DateTimeInput
          hour={24}
          mintues={0}
          source="promotion.validStartDate"
          label="validStartDate"
          validate={required()}
          defaultValue={promotionDetails?.validStartDate}
        />
        <DateTimeInput
          source="promotion.validEndDate"
          label="validEndDate"
          validate={required()}
          defaultValue={promotionDetails?.validEndDate}
        />

        <DateTimeInput
          disabled
          source="createdAt"
          label="createdAt"
          defaultValue={promotionDetails?.createdAt}
        />
        <DateTimeInput
          disabled
          source="promotion.updatedAt"
          label="updatedAt"
          defaultValue={promotionDetails?.updatedAt}
        />

        <ReferenceInputA
          filterKey="pdf"
          label="Flyer"
          source="promotion.flyer.id"
          defaultValue={promotionDetails?.flyer?.id}
        >
          <AutocompleteInputA resettable optionText="pdf" />
        </ReferenceInputA>

        <ArrayInputGfImages
          imageValidate={imageValidate}
          inputText={inputText}
        />
        <ArrayInput
          source="promotion.promotionTag"
          label="promotionTag"
          defaultValue={promotionDetails?.promotionTag}
        >
          <SimpleFormIterator>
            <ReferenceInputA
              filterKey="name"
              label="Tags"
              source="id"
              reference="admin-promotion-tag"
            >
              <AutocompleteInputA resettable optionText="name" />
            </ReferenceInputA>
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput
          source="promotion.isExclusive"
          label=" isExclusive"
          defaultValue={promotionDetails?.isExclusive}
        />
        <NumberInputA
          source="promotion.rank"
          defaultValue={promotionDetails?.rank}
        />
        <ArrayInput source="productTag">
          <SimpleFormIterator>
            <ReferenceInputA
              filterKey="name"
              label="Product Tags"
              source="id"
              reference="admin-product-tag"
            >
              <AutocompleteInputA resettable optionText="name" />
            </ReferenceInputA>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default EditProduct;
