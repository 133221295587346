import { Create, required, SimpleForm, TextInput } from 'react-admin';

const create = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput fullWidth source="userName" validate={required()} />
        <TextInput fullWidth source="password" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default create;
