import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  Edit,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Title,
} from 'react-admin';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import DateTimeInputA from '../Components/DateTimeInputA';
import NumberInputA from '../Components/NumberInputA';
import ReferenceInputA from '../Components/ReferenceInputA';
import TextInputA from '../Components/TextInputA';
import { parseImageToGfImages } from '../Utils/utils';

const transform = (data: any) => {
  return {
    ...data,
    gfImages: parseImageToGfImages(data.gfImages),
  };
};

const edit = (props: any) => (
  <Edit {...props} mutationMode="pessimistic" transform={transform}>
    <SimpleForm redirect="list">
      <TextInputA resettable disabled source="id" />
      <BooleanInput source="isExclusive" />
      <NumberInputA source="rank" />
      <NumberInputA source="quantity" />
      <NumberInputA source="price" validate={required()} />
      <TextInput source="unit" />
      <DateTimeInputA source="validStartDate" validate={required()} />
      <DateTimeInputA source="validEndDate" validate={required()} />
      {/* <NumberInputA source="limitQuantityPerFamily" /> */}
      <DateTimeInput disabled source="createdAt" />
      <DateTimeInput disabled source="updatedAt" />
      <DateTimeInput source="publishAt" />

      {/* <ArrayInput
				source="gfImages"
				label="Promotional Image(If uploaded, will show this instead of product image)"
			>
				<SimpleFormIterator>
					<ReferenceInputA filterKey="alt" label="Image" source="id" reference="admin-image">
						<AutocompleteInputA
							fullWidth
							resettable
							matchSuggestion={(_filterValue: any) => true}
							optionText={<OptionRenderer />}
							inputText={inputText}
						/>
					</ReferenceInputA>
				</SimpleFormIterator>
			</ArrayInput> */}

      <ReferenceInputA
        filterKey="product.name"
        label="Product"
        source="placement.id"
        reference="admin-placement"
        validate={required()}
      >
        <AutocompleteInputA resettable optionText="product.name" />
      </ReferenceInputA>
      <ReferenceInputA
        filterKey="pdf"
        label="Flyer"
        source="flyer.id"
        reference="admin-flyer"
      >
        <AutocompleteInputA resettable optionText="pdf" />
      </ReferenceInputA>

      <ArrayInput source="promotionTag">
        <SimpleFormIterator>
          <ReferenceInputA
            filterKey="name"
            label="Tags"
            source="id"
            reference="admin-promotion-tag"
          >
            <AutocompleteInputA resettable optionText="name" />
          </ReferenceInputA>
        </SimpleFormIterator>
      </ArrayInput>
      <SelectInput
        required={true}
        source="showAt"
        choices={[
          { id: 'goflyer', name: 'GoFlyer website & App' },
          { id: 'supermarket', name: 'Store website & App' },
          { id: 'both', name: 'Both GoFlyer & Store (website/App)' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export default edit;
