import {
  Create,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  ImageField,
  useMutation,
  useRedirect,
  useNotify,
} from 'react-admin';
import TextInputA from '../Components/TextInputA';
import React, { useCallback, useState } from 'react';
import Loader from '../Components/Loader';
import ImageInputA from '../Components/ImageInputA';

const create = (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const notify = useNotify();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutate] = useMutation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const redirect = useRedirect();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [url, setUrl] = useState<object[]>([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [percentage, setPercentage] = useState(0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);

  const handleSave = async (event: any) => {
    try {
      setOpen(true);
      const response = event?.map(async (value: any) => {
        return await uploadToS3(value);
      });
      const result: object[] = await Promise.all(response);
      setUrl(result);
      setOpen(false);
    } catch (error) {
      console.log(' error file', error);
    }
  };
  function uploadFile(file: any, jsonResult: any) {
    const options = {
      method: 'PUT',
      body: file,
    };
    return fetch(jsonResult.signedUrl, options).then(response => {
      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      return {
        alt: jsonResult.filename.substring(0, jsonResult.filename.indexOf('.')),
        src: response.url.substring(0, response.url.indexOf('?')),
      };
    });
  }
  function uploadToS3(file: any) {
    return getSignedRequest(file)
      .then((json: any) => uploadFile(file, json))
      .then(url => {
        return url;
      })
      .catch(err => {
        console.error(err);
        return null;
      });
  }

  function getSignedRequest(file: any) {
    return fetch(
      `${window.config.REACT_APP_GOFLYER_API_END_POINT}/s3/sign?objectName=${file.name}&contentType=${file.type}&path=flyers`,
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then(result => {
        return result;
      });
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const save = useCallback(
    async values => {
      try {
        if (url.length === 0) {
          notify('Please select at least one image');
          window.location.reload();
          return;
        }
        await mutate(
          {
            type: 'create',
            resource: 'admin-image',
            payload: { data: url },
          },
          { returnPromise: true },
        );
        redirect('list', props.basePath);
      } catch (error) {
        notify('Please select at least one image');
      }
    },
    [url, mutate],
  );
  return (
    <div>
      <Create {...props}>
        <SimpleForm redirect="list" save={save}>
          <ImageInputA
            source="gfImages"
            label="Related pictures"
            accept="image/*"
            multiple={true}
            onChange={handleSave}
          >
            <ImageField source="src" title="title" />
          </ImageInputA>

          {url.length > 0 &&
            url.map((value: any) => {
              return (
                <>
                  <div className="img-src-container">
                    <p>alt* </p>
                    <p className="src"> {value.alt}</p>
                  </div>
                  <div className="img-src-container">
                    <p>src*</p>
                    <p className="src">{value.src}</p>
                  </div>
                </>
              );
            })}
          {/* <NumberInputA fullWidth source="height" />
					<NumberInputA fullWidth source="width" /> */}
        </SimpleForm>
      </Create>
      <Loader open={open}></Loader>
    </div>
  );
};
export default create;
