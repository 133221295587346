import { TextField } from 'react-admin';
import ListA from '../Components/ListA';
import { Alert, AlertTitle } from '@mui/material';
import { useState } from 'react';
import { defaultApiService } from '../Utils/api';
import { TriggerButton } from './TriggerButton';

const List = (props: any) => {
  const [buttonList] = useState([
    {
      name: 'Start Indexing Flyers',
      condition: 'flyer',
    },
    {
      name: 'Start Indexing Promotions',
      condition: 'promotion',
    },
    {
      name: 'Start Indexing Stores',
      condition: 'store',
    },
    {
      name: 'Start Indexing Merchants',
      condition: 'merchant',
    },
    {
      name: 'Start Indexing Menus',
      condition: 'menu',
    },
    // {
    // 	name: 'Start Indexing Images',
    // 	condition: 'image',
    // },
  ]);

  const handleTrigger = async (condition: string) => {
    try {
      const response =
        await defaultApiService.searchControllerTriggerElasticSearch(condition);
      const result = await response.json();
      if (result?.message === 'successful') {
        return 'success';
      } else {
        return 'error';
      }
    } catch (error) {
      console.log('error', error);
      return 'error';
    }
  };

  return (
    <>
      <div className="ElasticToolBar">
        {buttonList.map(value => {
          return (
            <TriggerButton
              key={value.condition}
              buttonName={value.name}
              condition={value.condition}
              handleTrigger={handleTrigger}
              dataTestId={`${value.condition}-search`}
            />
          );
        })}
      </div>
      {/* <ListA {...props}>
				<TextFieldA source="id" />
			</ListA> */}
    </>
  );
};

export default List;
