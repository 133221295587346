import React from 'react';
import {
  Admin,
  Resource,
  ShowGuesser,
  ListGuesser,
  EditGuesser,
} from 'react-admin';
import crudProvider from './Utils/dataProvider.js';
import Promotions from './Promotions';
import Products from './Products';
import Placements from './Placements';
import Merchants from './Merchants';
import Notifications from './Notifications';
import Users from './AdminUser';
import Elastic from './ElasticSearch';
import Cache from './Cache';

import Feedback from './Feedback';
import Stores from './Stores';
import Customers from './Customers';
import Images from './Images';
import Flyers from './Flyers';
import Menus from './Menus';
import FlyerTags from './FlyerTags';
import MenuTags from './MenuTags';
import PromotionTags from './PromotionTags';
import ProductTags from './ProductTags';
import StoreTags from './StoreTags';
import VersionControl from './VersionControl';

import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import './App.css';
import authProvider from './Auth/authProvider';
import LogRocket from 'logrocket';
LogRocket.init('mkk19k/goflyer-admin');

if (window.config.REACT_APP_GOFLYER_API_END_POINT === undefined) {
  console.error('Missing Env Variable');
}

if (window.config.REACT_APP_NOTIFICATION_API_END_POINT === undefined) {
  console.error('Missing Env Variable');
}

const dataProvider = crudProvider(
  window.config.REACT_APP_GOFLYER_API_END_POINT,
  window.config.REACT_APP_NOTIFICATION_API_END_POINT,
);
const theme = merge({}, defaultTheme, {
  overrides: {
    RaTopToolbar: {
      root: {
        '&& [aria-label="Create"]': {
          top: 'auto',
          left: 'auto',
          right: 20,
          bottom: 60,
          margin: 0,
          zIndex: 1000,
          position: 'fixed',
          width: 100,
          height: 100,
          borderRadius: 50,
          background: '#3f51b5',
          color: 'white',
        },
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        flexDirection: 'row-reverse',
        justifyContent: 'start',
      },
      title: {
        flexDirection: 'row-reverse',
        alingItems: 'center',
        marginLeft: '5px',
        marginTop: '2px',
      },
    },
    MuiSvgIcon: {
      root: {
        marginLeft: '10px',
        marginTop: '-3px',
      },
    },
    RaButton: {
      label: {
        paddingTop: '3px',
      },
    },
  },
});
const App = () => (
  <Admin authProvider={authProvider} theme={theme} dataProvider={dataProvider}>
    <Resource
      options={{ label: 'Merchants' }}
      name="admin-merchant"
      list={Merchants.list}
      edit={Merchants.edit}
      show={ShowGuesser}
      create={Merchants.create}
    />
    <Resource
      options={{ label: 'Stores' }}
      name="admin-store"
      list={Stores.list}
      edit={Stores.edit}
      show={ShowGuesser}
      create={Stores.create}
    />
    <Resource
      options={{ label: 'Products' }}
      name="admin-product"
      list={Products.list}
      edit={Products.edit}
      show={ShowGuesser}
      create={Products.create}
    />
    <Resource
      options={{ label: 'Placements' }}
      name="admin-placement"
      list={Placements.list}
      edit={Placements.edit}
      show={ShowGuesser}
      create={Placements.create}
    />
    <Resource
      options={{ label: 'Flyers' }}
      name="admin-flyer"
      list={Flyers.list}
      edit={Flyers.edit}
      show={ShowGuesser}
      create={Flyers.create}
    />
    <Resource
      options={{ label: 'Menus' }}
      name="admin-menu"
      list={Menus.list}
      edit={Menus.edit}
      show={ShowGuesser}
      create={Menus.create}
    />
    <Resource
      options={{ label: 'Promotions' }}
      name="admin-promotion"
      list={Promotions.list}
      create={Promotions.create}
      edit={Promotions.edit}
      show={ShowGuesser}
    />
    <Resource
      options={{ label: 'Customers' }}
      name="admin-customer"
      list={Customers.list}
      edit={Customers.edit}
      show={ShowGuesser}
      create={Customers.create}
    />
    <Resource
      options={{ label: 'Images' }}
      name="admin-image"
      list={Images.list}
      edit={Images.edit}
      show={ShowGuesser}
      create={Images.create}
    />
    <Resource
      options={{ label: 'Store Tags' }}
      name="admin-store-tag"
      list={StoreTags.list}
      edit={StoreTags.edit}
      show={ShowGuesser}
      create={StoreTags.create}
    />
    <Resource
      options={{ label: 'Flyer Tags' }}
      name="admin-flyer-tag"
      list={FlyerTags.list}
      edit={FlyerTags.edit}
      show={ShowGuesser}
      create={FlyerTags.create}
    />
    <Resource
      options={{ label: 'Menu Tags' }}
      name="admin-menu-tag"
      list={MenuTags.list}
      edit={MenuTags.edit}
      show={ShowGuesser}
      create={MenuTags.create}
    />
    <Resource
      options={{ label: 'Promotion Tags' }}
      name="admin-promotion-tag"
      list={PromotionTags.list}
      edit={PromotionTags.edit}
      show={ShowGuesser}
      create={PromotionTags.create}
    />
    <Resource
      options={{ label: 'Product Tags' }}
      name="admin-product-tag"
      list={ProductTags.list}
      edit={ProductTags.edit}
      show={ShowGuesser}
      create={ProductTags.create}
    />
    <Resource
      options={{ label: 'Notifications' }}
      name="admin-notification"
      list={Notifications.list}
      // edit={Notifications.edit}
      show={ShowGuesser}
      create={Notifications.create}
    />
    <Resource
      options={{ label: 'Admin Users' }}
      name="admin-admin-user"
      list={Users.list}
      edit={Users.edit}
      show={ShowGuesser}
      create={Users.create}
    />
    <Resource
      options={{ label: 'Elastic Trigger' }}
      name="elastic-search"
      list={Elastic.list}
      show={ShowGuesser}
    />
    <Resource
      options={{ label: 'Cache' }}
      name="Cache"
      list={Cache.list}
      show={ShowGuesser}
    />
    <Resource
      options={{ label: 'FeedBack' }}
      name="admin-feedback"
      list={Feedback.list}
      show={ShowGuesser}
    />

<Resource
      options={{ label: 'VersionControl' }}
      name="admin-version-control"
      list={VersionControl.list}
      edit={VersionControl.edit}
      show={ShowGuesser}
      create={VersionControl.create}
    />
  </Admin>
);
export default App;
