import { ImageField, NumberField, SearchInput } from 'react-admin';
import CustomDataGrid from '../Components/CustomDataGrid';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import TextFieldA from '../Components/TextFieldA';

const list = (props: any) => {
  const postFilters = [<SearchInput source="q" alwaysOn resettable />];
  return (
    <ListA {...props} filters={postFilters}>
      <CustomDataGrid data-testid="merchant CustomDataGrid list">
        <TextFieldA source="name" />
        <TextFieldA source="nameChinese" />
        <ImageField source="merchantMapSmallLogo.src" title="alt" />
        <ImageField source="merchantMapLargeLogo.src" title="alt" />
        <ImageField source="merchantLogo.src" title="alt" />
        <TextFieldA source="address" />
        <TextFieldA source="city" />
        <TextFieldA source="country" />
        <TextFieldA source="province" />
        <TextFieldA source="street" />
        <TextFieldA source="description" />
        <TextFieldA source="zip" />

        <TextFieldA source="phone" />
        <NumberField source="productsCount" />
        <DateFieldA showTime source="createdAt" />
        <TextFieldA source="domain" />
        <TextFieldA source="refundPolicy" />
        <DateFieldA showTime source="updatedAt" />
        <TextFieldA source="id" />
      </CustomDataGrid>
    </ListA>
  );
};

export default list;
