import {
  Datagrid,
  DateField,
  ImageField,
  List,
  NumberField,
  TextField,
} from 'react-admin';
import CustomDataGrid from '../Components/CustomDataGrid';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import TextFieldA from '../Components/TextFieldA';

const list = (props: any) => {
  return (
    <ListA {...props}>
      <CustomDataGrid>
        <TextFieldA source="id" />
        <TextFieldA source="alt" />
        {/* <TextFieldA source="mediaType" /> */}
        <ImageField source="src" title="alt" />
        {/* <NumberField source="height" />
				<NumberField source="width" /> */}
        <DateFieldA showTime source="createdAt" />
        <DateFieldA showTime source="updatedAt" />
      </CustomDataGrid>
    </ListA>
  );
};

export default list;
