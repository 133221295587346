import React from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { Datagrid, DatagridBody, RecordContextProvider } from 'react-admin';

const CustomDataGrid = (props: any) => {
  const onClickUrl = (e: any, url: string) => {
    if (e?.ctrlKey || e?.metaKey) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      window.open(url, '_self');
    }
  };

  const MyDatagridRow = ({
    record,
    id,
    onToggleItem,
    children,
    selected,
    selectable,
    basePath,
  }: any) => (
    <RecordContextProvider value={record}>
      <TableRow className="TableRowGrid">
        <TableCell padding="none">
          <Checkbox
            checked={selected}
            onClick={event => onToggleItem(id, event)}
          />
        </TableCell>
        {React.Children.map(children, field => (
          <TableCell
            className="TableCellGrid"
            key={`${id}-${field.props.source}`}
            onClick={e => {
              onClickUrl(e, `#${basePath}/${record.id}`);
            }}
          >
            {field}
          </TableCell>
        ))}
      </TableRow>
    </RecordContextProvider>
  );

  const CustomDataGridBody = (props: any) => (
    <DatagridBody {...props} row={<MyDatagridRow {...props} />} />
  );
  const CustomGrid = (props: any) => (
    <Datagrid {...props} body={<CustomDataGridBody />} />
  );

  return <CustomGrid {...props}></CustomGrid>;
};

export default CustomDataGrid;
