import {
  ArrayInput,
  AutocompleteArrayInput,
  DateTimeInput,
  Edit,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useMutation,
  useNotify,
  useRedirect,
} from 'react-admin';
import ReactS3Uploader from 'react-s3-uploader';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import Loader from '../Components/Loader';
import ReferenceInputA from '../Components/ReferenceInputA';
import React, { useCallback, useState } from 'react';
import TextInputA from '../Components/TextInputA';
import NumberInputA from '../Components/NumberInputA';
import DateTimeInputA from '../Components/DateTimeInputA';
import ReferenceArrayInputA from '../Components/ReferenceArrayInputA';
import { adminApiService } from '../Utils/api';
import { GfImage, GfStore } from '@swagger/typescript-fetch-goflyer';
import { parseStoreToGfStores } from '../Utils/utils';
import ImageInputA from '../Components/ImageInputA';
import { ArrayInputGfImages } from '../Components/ArrayInputGfImages';
import { uploadImageToS3 } from '../Utils/image';

const OptionRenderer = (choice: any) => {
  return (
    <span>
      <img style={{ width: 100 }} src={choice.record.src} />
      {choice.record.alt} {choice.record.src}
    </span>
  );
};
const inputText = (choice: any) => `${choice.alt} ${choice.src}`;
const EditFlyer = (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [requiredStore, setRequiredStore] = useState(true);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const transform = async (data: any) => {
    if (data && data.merchant) {
      const stores =
        (await adminApiService.getManyBaseGfStoreAdminControllerGfStore(
          undefined,
          `{"merchant.id": "${data.merchant}"}`,
        )) as {
          count: number;
          data: GfStore[];
          page: number;
          pageCount: number;
          total: number;
        }; //TODO: temp fix
      if (stores && stores.data.length === 0) {
        alert('No store belong to this merchant');
        return;
      }
      data.stores = stores.data;
    }
    return {
      ...data,
      stores: parseStoreToGfStores(data.stores),
    };
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutate] = useMutation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const redirect = useRedirect();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [url, setUrl] = useState<object[]>([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);
  const notify = useNotify();

  const handleImageSave = async (event: any) => {
    try {
      setOpen(true);
      const response = event?.map(async (value: any) => {
        return await uploadImageToS3(value, false);
      });
      const result: object[] = await Promise.all(response);
      setUrl(result);
      setOpen(false);
    } catch (error) {
      console.log(' error file', error);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const editFlyer = useCallback(
    async values => {
      try {
        const resullt =
          (await adminApiService.createOneBaseGfImageAdminControllerGfImage(
            url as any,
          )) as GfImage[];
        const storesData = await transform(values);
        const { gfImagess, ...others } = storesData;
        const finalData = {
          ...others,
          gfImages: [...others.gfImages, ...resullt],
        };

        if (finalData.gfImages.length) {
          await mutate(
            {
              type: 'update',
              resource: 'admin-flyer',
              payload: { id: finalData.id, data: finalData },
            },
            { returnPromise: true },
          );
          redirect('list', props.basePath);
        } else {
          notify('GfImages are required please add at-least one image');
        }
      } catch (error) {
        console.log('storesData error', error);
      }
    },
    [url, mutate],
  );
  const optionRenderer = (store: any) =>
    `${store?.merchant?.name}, ${
      store?.signature !== null ? store?.signature : ''
    }`;
  const validate = (props: any) => {};
  const imageValidate = [required(), validate];
  return (
    <div>
      <Edit {...props} undoable={false}>
        <SimpleForm redirect="list" save={editFlyer}>
          <TextInputA disabled source="id" />
          <DateTimeInput disabled source="createdAt" />
          <DateTimeInput disabled source="updatedAt" />
          {/* <ReactS3Uploader
						signingUrl={`${window.config.REACT_APP_GOFLYER_API_END_POINT}/s3/sign`}
						signingUrlMethod="GET"
						accept="application/pdf"
						s3path="flyers"
						autoUpload={true}
						preprocess={preprocess}
						onFinish={onUploadFinish}
						onProgress={onUploadProgress}
					/> */}
          <TextInputA
            helperText="Put anything that you can recognize"
            source="pdf"
            label="Name"
            validate={required()}
            resettable
            fullWidth
          />
          <NumberInputA source="rank" />
          <ReferenceInputA
            fullWidth
            filterKey="name"
            perPage={200}
            label="Merchant"
            sort={{ field: 'name', order: 'ASC' }}
            source="merchant"
            reference="admin-merchant"
            onChange={() => {
              setRequiredStore(false);
            }}
          >
            <AutocompleteInputA
              resettable
              matchSuggestion={(_filterValue: any) => true}
            />
          </ReferenceInputA>
          <ReferenceArrayInputA
            validate={requiredStore ? required() : undefined}
            filterKey="merchant.name"
            perPage={40}
            label="Store"
            sort={{ field: 'merchant.name', order: 'ASC' }}
            source="stores"
            reference="admin-store"
          >
            <AutocompleteArrayInput
              matchSuggestion={(_filterValue: any) => true}
              resettable
              optionText={optionRenderer}
            />
          </ReferenceArrayInputA>
          <DateTimeInputA source="validStartDate" validate={required()} />
          <DateTimeInputA source="validEndDate" validate={required()} />
          <ImageInputA
            source="gfImagess"
            accept="image/*"
            multiple={true}
            onChange={handleImageSave}
            // validate={required()}
          >
            <ImageField source="src" title="title" />
          </ImageInputA>
          <ArrayInputGfImages
            imageValidate={imageValidate}
            inputText={inputText}
          />

          <ArrayInput source="flyerTag">
            <SimpleFormIterator>
              <ReferenceInputA
                filterKey="name"
                label="Tags"
                source="id"
                reference="admin-flyer-tag"
              >
                <AutocompleteInputA resettable optionText="name" />
              </ReferenceInputA>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
      <Loader open={open}></Loader>
    </div>
  );
};

export default EditFlyer;
