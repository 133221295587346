import {
  ArrayField,
  ChipField,
  ImageField,
  NumberField,
  ReferenceManyField,
  SearchInput,
  SingleFieldList,
} from 'react-admin';
import CustomDataGrid from '../Components/CustomDataGrid';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import TextFieldA from '../Components/TextFieldA';

const postFilters = [<SearchInput source="q" alwaysOn />];
const list = (props: any) => {
  return (
    <ListA {...props} filters={postFilters}>
      <CustomDataGrid>
        <ArrayField source="storeTag">
          <SingleFieldList>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
        <NumberField source="rank" />
        <TextFieldA label="Merchant" source="merchant.name" />
        <TextFieldA source="signature" />
        <ImageField
          label="Store Image"
          source="gfImages[0].src"
          sortable={false}
          title="alt"
        />
        <TextFieldA source="address" />
        <TextFieldA source="city" />
        <TextFieldA source="country" />
        <TextFieldA source="province" />
        <TextFieldA source="street" />
        <TextFieldA source="zip" />
        <TextFieldA source="location.type" sortable={false} />
        <TextFieldA
          label="Longitude"
          source="location.coordinates[0]"
          sortable={false}
        />
        <TextFieldA
          label="Latitude"
          source="location.coordinates[1]"
          sortable={false}
        />
        <TextFieldA source="phone" />
        <NumberField source="googleStar" />
        <NumberField source="googleNumberOfReviews" />
        <NumberField source="numberOfDeals" />
        <NumberField source="openTime" />
        <NumberField source="closeTime" />
        <DateFieldA showTime source="createdAt" />
        <DateFieldA showTime source="updatedAt" />
        <TextFieldA source="id" />
      </CustomDataGrid>
    </ListA>
  );
};

export default list;
