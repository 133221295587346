import {
  CreateButton,
  NumberField,
  TopToolbar,
  ExportButton,
  ImageField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  SearchInput,
  ArrayField,
} from 'react-admin';
import { ImportButton } from 'react-admin-import-csv';
import CustomDataGrid from '../Components/CustomDataGrid';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import TextFieldA from '../Components/TextFieldA';

const ListActions = (props: any) => {
  const { className, basePath } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <ExportButton basePath={basePath} />
      <ImportButton {...props} />
    </TopToolbar>
  );
};
const list = (props: any) => {
  const postFilters = [<SearchInput source="q" alwaysOn />];
  return (
    <ListA {...props} actions={<ListActions />} filters={postFilters}>
      <CustomDataGrid>
        <ArrayField source="promotionTag">
          <SingleFieldList>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
        <NumberField source="rank" />
        <TextFieldA label="Product" source="placement.product.name" />
        <TextFieldA label="Store" source="placement.store.merchant.name" />
        <ImageField
          label="Product Image"
          source="placement.product.gfImages.[0].src"
          sortable={false}
          title="alt"
        />
        {/* <ImageField label="Promotion Image" source="gfImages.[0].src" sortable={false} title="alt" /> */}
        <TextFieldA label="Flyer" source="flyer.pdf" sortable={false} />
        <NumberField source="quantity" />
        <TextFieldA label="Unit" source="unit" />
        <NumberField label="Promotional Price" source="price" />
        <NumberField label="Original Price" source="placement.price" />
        <DateFieldA source="validStartDate" />
        <DateFieldA source="validEndDate" />
        <DateFieldA showTime source="createdAt" />
        <DateFieldA showTime source="updatedAt" />
        {/* <DateFieldA showTime source="publishAt" /> */}
        <TextFieldA source="id" />
      </CustomDataGrid>
    </ListA>
  );
};

export default list;
