import {
  ArrayInput,
  DateTimeInput,
  Edit,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  minValue,
  maxValue,
} from 'react-admin';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import ReferenceInputA from '../Components/ReferenceInputA';
import { parseFlyerToGfFlyers, parseImageToGfImages } from '../Utils/utils';
import TextInputA from '../Components/TextInputA';
import NumberInputA from '../Components/NumberInputA';

const edit = (props: any) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="list">
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required()} />
      <TextInput source="nameChinese" validate={required()} />
      <NumberInputA source="index" validate={required()} />
      <DateTimeInput disabled showTime source="createdAt" />
      <DateTimeInput disabled showTime source="updatedAt" />
    </SimpleForm>
  </Edit>
);

export default edit;
