function uploadImageFile(file: any, jsonResult: any) {
  const options = {
    method: 'PUT',
    body: file,
  };
  return fetch(jsonResult.signedUrl, options).then(response => {
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return {
      alt: jsonResult.filename.substring(0, jsonResult.filename.indexOf('.')),
      src: response.url.substring(0, response.url.indexOf('?')),
    };
  });
}

function uploadImageToS3(file: any, small: boolean) {
  if (file) {
    return getSignedRequest(file, small)
      .then(async (json: any) => uploadImageFile(file, json))
      .then(url => {
        return url;
      })
      .catch(err => {
        console.error(err);
        return null;
      });
  }
}

function getSignedRequest(file: any, small: boolean) {
  const unique13digitNumber = Math.floor(
    1000000000 + Date.now() + Math.random() * 9000000000,
  );
  let n = file.name.lastIndexOf('.');
  let finalString =
    file.name.substring(0, n) +
    '_' +
    unique13digitNumber +
    file.name.substring(n);
  const FileName = encodeURIComponent(finalString)
  if (small) {
    return fetch(
      `${window.config.REACT_APP_GOFLYER_API_END_POINT}/s3/sign?objectName=small-${FileName}&contentType=${file.type}&path=flyers`,
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then(result => {
        return result;
      });
  } else {
    return fetch(
      `${window.config.REACT_APP_GOFLYER_API_END_POINT}/s3/sign?objectName=${FileName}&contentType=${file.type}&path=flyers`,
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then(result => {
        return result;
      });
  }
}
export { getSignedRequest, uploadImageToS3, uploadImageFile };
