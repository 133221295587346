export const OptionRenderer = (choice: any) => {
  return (
    <span>
      <img style={{ width: 100 }} src={choice.record.src} />
      {choice.record.alt} {choice.record.src}
    </span>
  );
};

export const inputText = (choice: any) => `${choice.alt} ${choice.src}`;
