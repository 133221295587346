function uploadZipFile(file: HTMLInputElement, jsonResult: any) {
  const options = {
    method: 'PUT',
    body: file as any,
  };
  return fetch(jsonResult.signedUrl, options).then(response => {
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return {
      alt: jsonResult.filename.substring(0, jsonResult.filename.indexOf('.')),
      src: response.url.substring(0, response.url.indexOf('?')),
    };
  });
}

function uploadZipFileToS3(file: HTMLInputElement, versionNumber: string) {
  if (file) {
    return getSignedRequest(file, versionNumber)
      .then(async (json: any) => uploadZipFile(file, json))
      .then(url => {
        return url;
      })
      .catch(err => {
        console.error(err);
        return null;
      });
  }
}

function getSignedRequest(file: HTMLInputElement, versionNumber: string) {
  const FileName = versionNumber + '/' + file.name;
  console.log('FileName', FileName);
  return fetch(
    `${window.config.REACT_APP_GOFLYER_API_END_POINT}/s3/sign?objectName=${FileName}&contentType=${file.type}&path=flyers`,
  )
    .then(response => {
      if (!response.ok) {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      return response.json();
    })
    .then(result => {
      return result;
    });
}
export { getSignedRequest, uploadZipFileToS3, uploadZipFile };
