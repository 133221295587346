export const parseImageToGfImages = (images: any) => {
  if (images === undefined) {
    return [];
  }
  const _gfImages = [];
  for (let index = 0; index < images.length; index++) {
    const image = images[index];
    _gfImages.push({
      id: image.image ? image.image.id : image.id,
    });
  }
  return _gfImages;
};

export const parseStoreToGfStores = (stores: any) => {
  if (stores === undefined) {
    return [];
  }
  const _gfStores = [];
  for (let index = 0; index < stores.length; index++) {
    const id = stores[index].id ? stores[index].id : stores[index];
    _gfStores.push({
      id,
    });
  }
  return _gfStores;
};

export const parseFlyerToGfFlyers = (flyers: any) => {
  if (flyers === undefined) {
    return [];
  }
  const _gfFlyers = [];
  for (let index = 0; index < flyers.length; index++) {
    const flyer = flyers[index];
    _gfFlyers.push({
      id: flyer.flyer ? flyer.flyer.id : flyer.id,
    });
  }
  return _gfFlyers;
};

export const storeOptionRenderer = (store: any) =>
  `${store?.merchant?.name}, ${
    store?.signature !== null ? store.signature : ''
  }`;
