import { Edit, required, SimpleForm, TextInput } from 'react-admin';

const edit = (props: any) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="list">
      <TextInput source="userName" validate={required()} />
      <TextInput source="password" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default edit;
