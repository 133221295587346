import {
  ArrayInput,
  AutocompleteArrayInput,
  Create,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  useMutation,
  useRedirect,
} from 'react-admin';
import React, { useCallback, useState } from 'react';
import ReferenceInputA from '../Components/ReferenceInputA';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import TextInputA from '../Components/TextInputA';
import { OptionRenderer, inputText } from '../Components/OptionRenderer';
import NumberInputA from '../Components/NumberInputA';
import DateTimeInputA from '../Components/DateTimeInputA';
import { parseStoreToGfStores } from '../Utils/utils';
import ReferenceArrayInputA from '../Components/ReferenceArrayInputA';
import { adminApiService, sharpApi } from '../Utils/api';
import {
  CreateGfImageDto,
  GfImage,
  GfImageDto,
  GfStore,
} from '@swagger/typescript-fetch-goflyer';
import Loader from '../Components/Loader';
import ImageInputA from '../Components/ImageInputA';
import { uploadImageToS3 } from '../Utils/image';
import { HorizontalSplitImage } from '@swagger/typescript-fetch-sharp-processor';

function parseSplitedLargeImageToImageDto(
  horizontalSplitImages: HorizontalSplitImage[],
) {
  const results: CreateGfImageDto[] = [];
  for (let index = 0; index < horizontalSplitImages.length; index++) {
    const horizontalSplitImage = horizontalSplitImages[index];
    results.push({
      src: horizontalSplitImage.location,
    });
  }
  return results;
}

const TCreate = (props: any) => {
  const [requiredStore, setRequiredStore] = useState(true);

  const transform = async (data: any) => {
    if (data && data.merchant) {
      const stores =
        (await adminApiService.getManyBaseGfStoreAdminControllerGfStore(
          undefined,
          `{"merchant.id": "${data.merchant}"}`,
        )) as {
          count: number;
          data: GfStore[];
          page: number;
          pageCount: number;
          total: number;
        }; //TODO: temp fix
      console.log(`stores`, stores);

      if (stores && stores.data.length === 0) {
        alert('No store belong to this merchant');
        return;
      }
      data.stores = stores.data;
    }
    return {
      ...data,
      stores: parseStoreToGfStores(data.stores),
    };
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutate] = useMutation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const redirect = useRedirect();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [url, setUrl] = useState<object[]>([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);

  const handleImageSave = async (event: any) => {
    try {
      const _URL = window.URL || window.webkitURL;
      setOpen(true);
      const imageFiles = event.filter((value: any) => value?.path);

      const response = imageFiles?.map(async (value: any) => {
        return new Promise(function (resolve, reject) {
          try {
            let img: any, file: any;
            file = value;
            if (file) {
              img = new Image();
              img.onload = async function () {
                const dividValue = this.height / this.width;
                if (dividValue > 2) {
                  const responseLarge = await uploadImageToS3(value, false);
                  const responseSmall: any =
                    await sharpApi.sharpModuleControllerResizeImage({
                      resizeImageDto: {
                        url: responseLarge?.src as string,
                        resize: {
                          width: 800,
                        },
                      },
                    });
                  // const responseSmall = await uploadImageToS3(
                  //   responseSmal,
                  //   true,
                  // );

                  const splitedLargeImage =
                    await sharpApi.sharpModuleControllerHorizontalSplitImage({
                      splitImageDto: {
                        url: responseLarge!.src,
                        numberOfSlice: 2,
                      },
                    });
                  const bulk_create_image_dto =
                    parseSplitedLargeImageToImageDto(splitedLargeImage);
                  const results =
                    (await adminApiService.createManyBaseGfImageAdminControllerGfImage(
                      {
                        bulk: bulk_create_image_dto,
                      },
                    )) as GfImage[];
                  resolve({
                    ...responseLarge,
                    srcSmall: responseSmall?.location,
                    gfSplitImages: results,
                  });
                } else {
                  const responseLarge = await uploadImageToS3(value, false);

                  const responseSmall: any =
                    await sharpApi.sharpModuleControllerResizeImage({
                      resizeImageDto: {
                        url: responseLarge?.src as string,
                        resize: {
                          height: 456,
                        },
                      },
                    });

                  // const responseSmall = await uploadImageToS3(resultSmal, true);
                  const splitedLargeImage =
                    await sharpApi.sharpModuleControllerHorizontalSplitImage({
                      splitImageDto: {
                        url: responseLarge!.src,
                        numberOfSlice: 2,
                      },
                    });
                  const bulk_create_image_dto =
                    parseSplitedLargeImageToImageDto(splitedLargeImage);
                  const results =
                    (await adminApiService.createManyBaseGfImageAdminControllerGfImage(
                      {
                        bulk: bulk_create_image_dto,
                      },
                    )) as GfImage[];
                  resolve({
                    ...responseLarge,
                    srcSmall: responseSmall?.location,
                    gfSplitImages: results,
                  });
                }
              };
              img.onerror = function () {
                alert('not a valid file: ' + file.type);
              };
              img.src = _URL.createObjectURL(file);
            }
          } catch (error) {
            reject(error);
          }
        });
      });

      const result: object[] = await Promise.all(response);
      setUrl([...url, ...result]);
      setOpen(false);
    } catch (error) {
      console.log(' error file', error);
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const saveFlyer = useCallback(
    async values => {
      try {
        const result =
          (await adminApiService.createOneBaseGfImageAdminControllerGfImage(
            url as any,
          )) as GfImage[];

        const storesData = await transform(values);
        const finalData = { ...storesData, gfImages: result };

        await mutate(
          {
            type: 'create',
            resource: 'admin-flyer',
            payload: { data: finalData },
          },
          { returnPromise: true },
        );
        redirect('list', props.basePath);
      } catch (error) {}
    },
    [url, mutate],
  );
  const startDateValidation = (value: any, allValues: any) => {
    if (!value) {
      return 'The start date is required';
    }
    if (
      new Date(value).getTime() > new Date(allValues?.validEndDate).getTime()
    ) {
      return 'The start date should be earlier then valid end date';
    }
    return undefined;
  };

  const endDateValidation = (value: any, allValues: any) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 3);
    if (!value) {
      return 'The end date is required';
    }
    if (new Date(value).getTime() < new Date().getTime()) {
      return 'End Date should be not less then start date';
    }
    if (
      new Date(value).getDate() === new Date(allValues.validStartDate).getDate()
    ) {
      return ' End Date should be same';
    }
    if (new Date(value).getTime() > currentDate.getTime()) {
      return 'Date should be within a month';
    }
    return undefined;
  };
  const validateStartDate = [required(), startDateValidation];
  const validateEndDate = [required(), endDateValidation];

  const optionRenderer = (store: any) =>
    `${store?.merchant?.name}, ${
      store?.signature !== null ? store.signature : ''
    }`;
  return (
    <div>
      <Create {...props} transform={transform}>
        <SimpleForm redirect="list" save={saveFlyer}>
          <TextInputA
            helperText="Put anything that you can recognize"
            label="Name"
            source="pdf"
            validate={required()}
            resettable
            fullWidth
          />
          <NumberInputA source="rank" />
          <label>如果选择了Merchant， 就不用选store了</label>
          <ReferenceInputA
            fullWidth
            filterKey="name"
            perPage={9999}
            label="Merchant"
            sort={{ field: 'name', order: 'ASC' }}
            source="merchant"
            reference="admin-merchant"
            onChange={(value: any) => {
              if (value === '') {
                setRequiredStore(true);
              } else {
                setRequiredStore(false);
              }
            }}
          >
            <AutocompleteInputA
              resettable
              matchSuggestion={(_filterValue: any) => true}
            />
          </ReferenceInputA>

          {requiredStore && (
            <div>
              <label>如果选择了Store， 就不要选Merchant了</label>
              <ReferenceArrayInputA
                validate={requiredStore ? required() : undefined}
                filterKey="merchant.name"
                perPage={40}
                label="Store"
                sort={{ field: 'merchant.name', order: 'ASC' }}
                source="stores"
                reference="admin-store"
              >
                <AutocompleteArrayInput
                  matchSuggestion={(_filterValue: any) => true}
                  resettable
                  optionText={optionRenderer}
                />
              </ReferenceArrayInputA>
            </div>
          )}

          <DateTimeInputA
            hour={24}
            mintues={1}
            source="validStartDate"
            validate={validateStartDate}
          />
          <DateTimeInputA
            hour={23}
            mintues={59}
            plusDays={7}
            source="validEndDate"
            validate={validateEndDate}
          />
          <ImageInputA
            source="gfImages"
            accept="image/*"
            multiple={true}
            onChange={handleImageSave}
            validate={required()}
          >
            <ImageField source="src" title="title" />
          </ImageInputA>

          <ArrayInput source="flyerTag" validate={required()}>
            <SimpleFormIterator>
              <ReferenceInputA
                filterKey="name"
                label="Tags"
                source="id"
                reference="admin-flyer-tag"
              >
                <AutocompleteInputA resettable optionText="name" />
              </ReferenceInputA>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
      <Loader open={open}></Loader>
    </div>
  );
};
export default TCreate;
