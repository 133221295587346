import {
  ArrayInput,
  Create,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  required,
  minValue,
  maxValue,
} from 'react-admin';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import ReferenceInputA from '../Components/ReferenceInputA';
import { parseFlyerToGfFlyers, parseImageToGfImages } from '../Utils/utils';
import TextInputA from '../Components/TextInputA';
import NumberInputA from '../Components/NumberInputA';
import { ArrayInputGfImages } from '../Components/ArrayInputGfImages';

const transform = (data: any) => ({
  ...data,
  location: {
    type: 'Point',
    coordinates: [data.Longitude, data.Latitude],
  },
  gfImages: parseImageToGfImages(data.gfImages),
});

const inputText = (choice: any) => `${choice.alt} ${choice.src}`;
const validate = (props: any) => {};
const imageValidate = [required(), validate];
const create = (props: any) => (
  <Create {...props} transform={transform}>
    <SimpleForm redirect="list">
      <NumberInputA
        helperText="e.g. -79.223408884"
        source="Longitude"
        validate={required()}
      />
      <NumberInputA
        helperText="e.g. 43.806198293"
        source="Latitude"
        validate={required()}
      />
      <TextInputA source="phone" validate={required()} />

      <NumberInputA source="googleNumberOfReviews" />
      <NumberInputA
        source="openTime"
        helperText="&uarr; Put value between 0 to 24, for 9:30am, use 9.5, for 9:30pm, use 21.5"
        validate={[
          required(),
          minValue(0, `Value too small`),
          maxValue(24, `Value too big`),
        ]}
      />
      <NumberInputA
        source="closeTime"
        helperText="&uarr; Put value between 0 to 24, for 9:30am, use 9.5, for 9:30pm, use 21.5"
        validate={[
          required(),
          minValue(0, `Value too small`),
          maxValue(24, `Value too big`),
        ]}
      />
      <NumberInputA
        source="sundayOpenTime"
        helperText="&uarr; Put value between 0 to 24, for 9:30am, use 9.5, for 9:30pm, use 21.5"
        validate={[
          required(),
          minValue(0, `Value too small`),
          maxValue(24, `Value too big`),
        ]}
      />
      <NumberInputA
        source="sundayClosedTime"
        helperText="&uarr; Put value between 0 to 24, for 9:30am, use 9.5, for 9:30pm, use 21.5"
        validate={[
          required(),
          minValue(0, `Value too small`),
          maxValue(24, `Value too big`),
        ]}
      />
      <NumberInputA
        source="saturdayOpenTime"
        helperText="&uarr; Put value between 0 to 24, for 9:30am, use 9.5, for 9:30pm, use 21.5"
        validate={[
          required(),
          minValue(0, `Value too small`),
          maxValue(24, `Value too big`),
        ]}
      />
      <NumberInputA
        source="saturdayCloseTime"
        helperText="&uarr; Put value between 0 to 24, for 9:30am, use 9.5, for 9:30pm, use 21.5"
        validate={[
          required(),
          minValue(0, `Value too small`),
          maxValue(24, `Value too big`),
        ]}
      />
      <ReferenceInputA
        filterKey="name"
        perPage={9999}
        label="Merchant"
        sort={{ field: 'name', order: 'ASC' }}
        source="merchant.id"
        reference="admin-merchant"
        validate={required()}
      >
        <AutocompleteInputA resettable optionText="name" />
      </ReferenceInputA>
      <NumberInputA source="googleStar" max={5} min={0} />
      <NumberInput source="rank" />
      <TextInputA resettable source="address" />
      <TextInputA resettable source="city" />
      <TextInputA resettable source="country" />
      <TextInputA resettable source="province" />
      <TextInputA resettable source="street" />
      <TextInputA resettable source="zip" />
      <TextInputA resettable source="googleMapUrl" />

      <NumberInputA disabled source="numberOfDeals" />

      <TextInputA
        resettable
        helperText="This will appear after the store name, usually you want to put location here, for example, Woodbine. The store name will become Winco Woodbine"
        source="signature"
      />
      <ArrayInputGfImages imageValidate={imageValidate} inputText={inputText} />

      <ArrayInput source="storeTag">
        <SimpleFormIterator>
          <ReferenceInputA
            filterKey="name"
            label="Tags"
            source="id"
            reference="admin-store-tag"
          >
            <AutocompleteInputA resettable optionText="name" />
          </ReferenceInputA>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export default create;
