import {
  Datagrid,
  DateField,
  ImageField,
  List,
  NumberField,
  TextField,
} from 'react-admin';
import CustomDataGrid from '../Components/CustomDataGrid';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import TextFieldA from '../Components/TextFieldA';

const list = (props: any) => {
  return (
    <ListA {...props}>
      <CustomDataGrid>
        <TextFieldA source="id" />
        <TextFieldA source="name" />
        <TextFieldA source="nameChinese" />
        <NumberField source="index" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </CustomDataGrid>
    </ListA>
  );
};

export default list;
