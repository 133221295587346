import {
  Create,
  DateTimeInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import NumberInputA from '../Components/NumberInputA';

const create = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
      <TextInput source="nameChinese" validate={required()} />
      <NumberInputA initialValue={1} source="index" validate={required()} />
      <DateTimeInput disabled showTime source="createdAt" />
      <DateTimeInput disabled showTime source="updatedAt" />
    </SimpleForm>
  </Create>
);

export default create;
