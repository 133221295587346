import { DateTimeInput } from 'react-admin';
import { useEffect, useState } from 'react';

const DateTimeInputA = (props: any) => {
  const [initialDate, setInitialDate] = useState(new Date());
  useEffect(() => {
    if (props.hour !== undefined && props.mintues !== undefined) {
      initialDate.setHours(props.hour);
      initialDate.setMinutes(props.mintues);
    }
    if (props.plusDays) {
      initialDate.setDate(initialDate.getDate() + props.plusDays);
    }
  }, []);

  return (
    <DateTimeInput initialValue={initialDate} fullWidth={true} {...props} />
  );
};

export default DateTimeInputA;
