import { Create, SimpleForm, required } from 'react-admin';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import NumberInputA from '../Components/NumberInputA';
import ReferenceInputA from '../Components/ReferenceInputA';
import { storeOptionRenderer } from '../Utils/utils';

const create = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <NumberInputA source="price" validate={required()} />
      <ReferenceInputA
        filterKey="name"
        perPage={9999}
        label="Product"
        sort={{ field: 'name', order: 'ASC' }}
        source="product.id"
        reference="admin-product"
        validate={required()}
      >
        <AutocompleteInputA resettable optionText="name" />
      </ReferenceInputA>
      <ReferenceInputA
        validate={required()}
        filterKey="merchant.name"
        perPage={9999}
        label="Store"
        sort={{ field: 'merchant.name', order: 'ASC' }}
        source="store.id"
        reference="admin-store"
      >
        <AutocompleteInputA resettable optionText={storeOptionRenderer} />
      </ReferenceInputA>
    </SimpleForm>
  </Create>
);

export default create;
