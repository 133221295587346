import React, { useEffect } from 'react';
import { adminApiService } from '../Utils/api';
import styled from '@emotion/styled';

const RenderImage = (props: any) => {
  const [imageSrc, setImageSrc] = React.useState<string>('');

  const GetImage = async () => {
    if (props?.Id) {
      const result: any =
        await adminApiService.getOneBaseGfImageAdminControllerGfImage(props.Id);
      setImageSrc(result?.src);
    }
  };

  useEffect(() => {
    GetImage();
  }, [props]);

  return <div>{imageSrc ? <Image src={imageSrc} /> : <></>}</div>;
};

export default RenderImage;

const Image = styled.img`
  max-height: 100px !important;
`;
