import React from 'react';
import TextFieldA from '../Components/TextFieldA';

import {
  ArrayField,
  ChipField,
  NumberField,
  ReferenceManyField,
  SearchInput,
  SingleFieldList,
} from 'react-admin';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import CustomDataGrid from '../Components/CustomDataGrid';

const list = (props: any) => {
  const postFilters = [<SearchInput source="q" alwaysOn />];

  return (
    <ListA {...props} filters={postFilters}>
      <CustomDataGrid>
        <ArrayField source="flyerTag">
          <SingleFieldList>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
        <TextFieldA source="pdf" />
        <TextFieldA label="Merchant" source="stores[0].merchant.name" />
        <TextFieldA label="Merchant" source="stores[0].merchant.nameChinese" />
        <TextFieldA label="Signature" source="stores[0].signature" />
        <DateFieldA source="validStartDate" />
        <DateFieldA source="validEndDate" />
        <NumberField source="rank" />
        <DateFieldA source="createdAt" />
        <DateFieldA source="updatedAt" />
        <TextFieldA source="id" />
      </CustomDataGrid>
    </ListA>
  );
};

export default list;
