import { DateTimeInput, Edit, SimpleForm, required } from 'react-admin';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import NumberInputA from '../Components/NumberInputA';
import { inputText, OptionRenderer } from '../Components/OptionRenderer';
import ReferenceInputA from '../Components/ReferenceInputA';
import TextInputA from '../Components/TextInputA';

const edit = (props: any) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="list">
      <TextInputA resettable disabled source="id" />
      <TextInputA resettable source="address" />
      <TextInputA resettable source="city" />
      <TextInputA resettable source="country" />
      <TextInputA resettable source="province" />
      <TextInputA resettable source="street" />
      <TextInputA resettable source="description" />
      <TextInputA resettable source="descriptionChinese" />
      <TextInputA resettable source="zip" />
      <TextInputA resettable source="name" validate={required()} />
      <TextInputA resettable source="nameChinese" validate={required()} />
      <TextInputA source="phone" />
      <NumberInputA source="productsCount" />
      <DateTimeInput disabled showTime source="createdAt" />
      <TextInputA resettable source="domain" validate={required()} />
      <TextInputA resettable source="refundPolicy" />
      <ReferenceInputA
        filterKey="alt"
        label="Merchant Logo"
        source="merchantLogo.id"
        reference="admin-image"
        validate={required()}
      >
        <AutocompleteInputA
          matchSuggestion={(_filterValue: any) => true}
          optionText={<OptionRenderer />}
          inputText={inputText}
          resettable
        />
      </ReferenceInputA>
      <ReferenceInputA
        filterKey="alt"
        label="Merchant Map Small Logo"
        source="merchantMapSmallLogo.id"
        reference="admin-image"
        validate={required()}
      >
        <AutocompleteInputA
          matchSuggestion={(_filterValue: any) => true}
          optionText={<OptionRenderer />}
          inputText={inputText}
          resettable
        />
      </ReferenceInputA>
      <ReferenceInputA
        filterKey="alt"
        label="Merchant Map Large Logo"
        source="merchantMapLargeLogo.id"
        reference="admin-image"
        validate={required()}
      >
        <AutocompleteInputA
          matchSuggestion={(_filterValue: any) => true}
          optionText={<OptionRenderer />}
          inputText={inputText}
          resettable
        />
      </ReferenceInputA>
      <DateTimeInput disabled showTime source="updatedAt" />
    </SimpleForm>
  </Edit>
);

export default edit;
