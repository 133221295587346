import { TextField } from 'react-admin';
import ListA from '../Components/ListA';
import { Alert, AlertTitle } from '@mui/material';
import { useState } from 'react';
import { adminApiService, defaultApiService } from '../Utils/api';
import { TriggerButton } from './TriggerButton';

const List = (props: any) => {
  const [buttonList] = useState([
    {
      name: 'Clear All Cache',
      condition: 'Cache',
    },
  ]);

  const handleTrigger = async (condition: string) => {
    try {
      const response =
        await adminApiService.gfMiscellaneousAdminControllerClearCache();

      if (response.status === 200) {
        return 'success';
      } else {
        return 'error';
      }
    } catch (error) {
      console.log('error', error);
      return 'error';
    }
  };

  return (
    <>
      <div className="ElasticToolBar">
        {buttonList.map(value => {
          return (
            <TriggerButton
              key={value.condition}
              buttonName={value.name}
              condition={value.condition}
              handleTrigger={handleTrigger}
            />
          );
        })}
      </div>
    </>
  );
};

export default List;
