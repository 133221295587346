import { Create, required, SimpleForm } from 'react-admin';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import ReferenceInputA from '../Components/ReferenceInputA';
import TextInputA from '../Components/TextInputA';

const create = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <ReferenceInputA
        filterKey="pdf"
        label="Flyer"
        source="flyer.id"
        reference="admin-flyer"
      >
        <AutocompleteInputA resettable optionText="pdf" />
      </ReferenceInputA>
      <TextInputA resettable source="url" />
      <TextInputA resettable validate={required()} source="title" />
      <TextInputA resettable validate={required()} source="body" />
    </SimpleForm>
  </Create>
);

export default create;
