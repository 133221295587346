import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);
interface Props {
  open?: boolean;
  percentage?: any;
}
const Loader = (props: Props) => {
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.backdrop}
      open={props.open ? props.open : false}
    >
      <CircularProgress color="inherit" />
      {props.percentage && `${props.percentage}%`}
    </Backdrop>
  );
};

export default Loader;
