import {
  DateTimeInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import TextInputA from '../Components/TextInputA';

const edit = (props: any) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="list">
      <TextInputA fullWidth disabled source="id" />
      <TextInputA fullWidth source="firstName" />
      <TextInputA fullWidth source="lastName" />
      <TextInputA fullWidth source="userName" />
      <TextInputA fullWidth source="email" />
      <TextInputA fullWidth source="phoneNumber" />
      <TextInputA fullWidth source="passwordHash" />
      <TextInputA fullWidth source="firebaseAuthResult" />
      <TextInputA fullWidth disabled source="notificationToken" />
      <DateTimeInput fullWidth disabled showTime source="createdAt" />
      <DateTimeInput fullWidth disabled showTime source="updatedAt" />
    </SimpleForm>
  </Edit>
);

export default edit;
