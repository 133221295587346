import create from './Create';
import edit from './Edit';
import list from './List';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list,
  create,
  edit,
};
