import {
  ArrayInput,
  BooleanInput,
  Create,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useRedirect,
  useNotify,
  ImageField,
  useMutation,
} from 'react-admin';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import ReferenceInputA from '../Components/ReferenceInputA';
import { parseImageToGfImages, storeOptionRenderer } from '../Utils/utils';
import TextInputA from '../Components/TextInputA';
import { adminApiService } from '../Utils/api';
import { GfImage, GfPlacement } from '@swagger/typescript-fetch-goflyer';
import * as React from 'react';
import NumberInputA from '../Components/NumberInputA';
import DateTimeInputA from '../Components/DateTimeInputA';
import Loader from '../Components/Loader';
import ImageInputA from '../Components/ImageInputA';
import { ArrayInputGfImages } from '../Components/ArrayInputGfImages';
import { uploadImageToS3 } from '../Utils/image';

const transform = (data: any) => {
  return {
    ...data,
    gfImages: parseImageToGfImages(data.gfImages),
  };
};

const inputText = (choice: any) => `${choice.alt} ${choice.src}`;

/* eslint-disable */
const create = (props: any) => {
  const [shouldCreatePromotion, setShouldCreatePromotion] =
    React.useState(true);
  const [imgShow, setImgShow] = React.useState('Idle');
  const redirectTo = useRedirect();
  const notify = useNotify();
  const onSuccess = (a: any, b: any, c: any) => {
    adminApiService
      .createOneBaseGfPlacementAdminControllerGfPlacement({
        price: a.data.price,
        product: a.data.id,
        store: a.data.store.id,
      } as GfPlacement)
      .then((value: any) => {
        if (a.data.createPromotionToo === true) {
          const promotionObj = {
            isExclusive: a.data.isExclusive,
            quantity: a.data.quantity,
            price: a.data.promoPrice,
            unit: a.data.unit,
            promotionTag: a.data.promotionTag,
            flyer: a.data.flyer?.id,
            validStartDate: a.data.validStartDate,
            validEndDate: a.data.validEndDate,
            limitQuantityPerFamily: a.data.limitQuantityPerFamily,
            placement: { id: value.id },
            rank: a.data.rank,
          };
          adminApiService
            .createOneBaseGfPromotionAdminControllerGfPromotion(promotionObj)
            .then(() => {
              // redirectTo(props.basePath);
              notify(`Placement and promotion also created`);
            })
            .catch(async error => {
              notify(`Error ${JSON.stringify(await error.json())}`, 'error');
            });
        } else {
          // redirectTo(props.basePath);
          notify(`Placement also created`);
        }
      })
      .catch(error => {
        console.log(`error`, error);

        notify(`Something went wrong`, `warning`);
      });
  };
  const onChangeCreatePromotion = (e: any) => {
    console.log(`e`, e === true);
    setShouldCreatePromotion(e === true);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutate] = useMutation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const redirect = useRedirect();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [url, setUrl] = React.useState<object[]>([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = React.useState(false);

  const handleImageSave = async (event: any) => {
    try {
      setOpen(true);
      setImgShow('Drag');
      const imageFiles = event?.filter((value: any) => value?.path);
      const response = imageFiles?.map(async (value: any) => {
        return await uploadImageToS3(value, false);
      });

      const UrlResult = url
        ?.map((value: any) => {
          const found = event?.find((val: any) =>
            val?.title?.includes(value?.alt),
          );
          if (found) {
            return value;
          }
          return undefined;
        })
        ?.filter((val: any) => val != undefined);
      const result: object[] = await Promise.all(response);

      setUrl([...UrlResult, ...result]);

      setOpen(false);
    } catch (error) {
      console.log(' error file', error);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const savePromotion = React.useCallback(
    async values => {
      if (imgShow != 'List' && imgShow != 'Idle' && imgShow === 'Drag') {
        try {
          const resullt =
            (await adminApiService.createOneBaseGfImageAdminControllerGfImage(
              url as any,
            )) as GfImage[];
          const storesData = await transform(values);
          const finalData = { ...storesData, gfImages: resullt };
          await mutate(
            {
              type: 'create',
              resource: 'admin-product',
              payload: { data: finalData },
            },
            {
              returnPromise: true,
              onSuccess: async (a: any) => {
                await onSuccess(a, undefined, undefined);
              },
            },
          );
          // redirect('list', props.basePath);
        } catch (error) {}
      } else if (imgShow === 'Idle') {
        try {
          await mutate(
            {
              type: 'create',
              resource: 'admin-product',
              payload: { data: values },
            },
            {
              returnPromise: true,
              onSuccess: async (a: any) => {
                await onSuccess(a, undefined, undefined);
              },
            },
          );
          // redirect('list', props.basePath);
        } catch (error) {}
      }
    },
    [url, mutate],
  );
  const validate = (props: any) => {
    setImgShow('List');
  };
  const startDateValidation = (value: any, allValues: any) => {
    if (!value) {
      return 'The start date is required';
    }

    return undefined;
  };
  const endDateValidation = (value: any, allValues: any) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 3);
    if (!value) {
      return 'The end date is required';
    }
    if (new Date(value).getTime() < new Date().getTime()) {
      return 'End Date should be not less then start date';
    }
    if (
      new Date(value).getTime() === new Date(allValues.validStartDate).getTime()
    ) {
      return ' End Date should not be same with start date';
    }
    if (new Date(value).getTime() > currentDate.getTime()) {
      return 'Date should be within a month';
    }
    return undefined;
  };
  const validateStartDate = [required(), startDateValidation];
  const validateEndDate = [required(), endDateValidation];
  const imageValidate = [required(), validate];
  const handleReset = () => {
    setImgShow('Idle');
    setUrl([]);
  };

  return (
    <div>
      <Create {...props}>
        <SimpleForm redirect="list" save={savePromotion}>
          <BooleanInput initialValue={true} source="available" />
          <BooleanInput
            source="createPromotionToo"
            defaultValue={true}
            onChange={onChangeCreatePromotion}
          />
          <NumberInputA source="rank" />
          {/* <NumberInputA
				onWheel={(e:any) => e.target.blur()} label="Original Price" fullWidth source="compareAtPrice" validate={required()} /> */}

          <TextInputA
            fullWidth
            resettable
            source="name"
            validate={required()}
          />
          <TextInputA fullWidth resettable source="nameChinese" />
          <TextInputA fullWidth resettable source="nameFrench" />
          <NumberInputA
            fullWidth
            label="Store Price"
            source="price"
            helperText="产品的原价。就是网站，被划掉的那个价格"
            validate={required()}
          />
          <NumberInputA
            fullWidth
            label="Sale(Promotional) Price"
            source="promoPrice"
            validate={shouldCreatePromotion ? required() : undefined}
          />
          <NumberInputA
            fullWidth
            source="quantity"
            defaultValue={1}
            // validate={shouldCreatePromotion ? required() : undefined}
          />
          <TextInput fullWidth source="unit" />
          <NumberInputA fullWidth source="limitQuantityPerFamily" />
          <TextInputA
            fullWidth
            resettable
            source="description"
            validate={required()}
          />
          <TextInputA
            fullWidth
            resettable
            source="descriptionChinese"
            validate={required()}
          />

          {/* <DateTimeInput fullWidth source="publishedAt" /> */}
          <ReferenceInputA
            fullWidth
            validate={required()}
            filterKey="merchant.name"
            perPage={40}
            label="Store"
            sort={{ field: 'merchant.name', order: 'ASC' }}
            source="store.id"
            reference="admin-store"
          >
            <AutocompleteInputA
              resettable
              optionText={storeOptionRenderer}
              matchSuggestion={(_filterValue: any) => true}
            />
          </ReferenceInputA>
          <DateTimeInputA
            hour={24}
            mintues={0}
            source="validStartDate"
            validate={shouldCreatePromotion ? validateStartDate : undefined}
          />
          <DateTimeInputA
            plusDays={7}
            hour={23}
            mintues={59}
            source="validEndDate"
            validate={shouldCreatePromotion ? validateEndDate : undefined}
          />
          <ReferenceInputA
            filterKey="pdf"
            label="Flyer"
            source="flyer.id"
            reference="admin-flyer"
          >
            <AutocompleteInputA resettable optionText="pdf" />
          </ReferenceInputA>
          {imgShow !== 'Idle' && (
            <button onClick={handleReset}>Reset Gf-Images</button>
          )}
          {imgShow === 'Idle' ? (
            <>
              <ArrayInputGfImages
                imageValidate={imageValidate}
                inputText={inputText}
              />

              <ImageInputA
                source="gfImages"
                accept="image/*"
                multiple={true}
                onChange={handleImageSave}
              >
                <ImageField source="src" title="title" />
              </ImageInputA>
            </>
          ) : imgShow === 'List' ? (
            <ArrayInputGfImages
              imageValidate={imageValidate}
              inputText={inputText}
            />
          ) : (
            <ImageInputA
              source="gfImages"
              accept="image/*"
              multiple={true}
              onChange={handleImageSave}
            >
              <ImageField source="src" title="title" />
            </ImageInputA>
          )}

          <ArrayInput source="productTag">
            <SimpleFormIterator>
              <ReferenceInputA
                filterKey="name"
                label="Product Tags"
                source="id"
                reference="admin-product-tag"
              >
                <AutocompleteInputA resettable optionText="name" />
              </ReferenceInputA>
            </SimpleFormIterator>
          </ArrayInput>

          <div style={{ opacity: shouldCreatePromotion ? 1 : 0.1 }}>
            <BooleanInput source="isExclusive" />

            <ArrayInput
              source="promotionTag"
              validate={shouldCreatePromotion ? required() : undefined}
            >
              <SimpleFormIterator>
                <ReferenceInputA
                  filterKey="name"
                  label="Tags"
                  source="id"
                  reference="admin-promotion-tag"
                  validate={shouldCreatePromotion ? required() : undefined}
                >
                  <AutocompleteInputA
                    resettable
                    optionText="name"
                    validate={shouldCreatePromotion ? required() : undefined}
                  />
                </ReferenceInputA>
              </SimpleFormIterator>
            </ArrayInput>
          </div>
        </SimpleForm>
      </Create>
      <Loader open={open}></Loader>
    </div>
  );
};

export default create;
