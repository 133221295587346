import { Edit, SimpleForm } from 'react-admin';
import TextInputA from '../Components/TextInputA';

const edit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInputA resettable disabled source="id" />
      <TextInputA resettable source="title" />
      <TextInputA resettable source="body" />
    </SimpleForm>
  </Edit>
);

export default edit;
