import {
  Create,
  FileInput,
  ImageField,
  required,
  SimpleForm,
  useMutation,
  useRedirect,
} from 'react-admin';
import React, { useCallback, useState } from 'react';
import TextInputA from '../Components/TextInputA';
import Loader from '../Components/Loader';
import { uploadZipFileToS3 } from '../Utils/zipFile';


const TCreate = (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutate] = useMutation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const redirect = useRedirect();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [url, setUrl] = useState<HTMLInputElement>();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);

  const handleFileUpload = async (event: HTMLInputElement) => {
    setOpen(true);

    setUrl(event);
    setOpen(false);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const saveFlyer = useCallback(
    async values => {
      try {
        setOpen(true);

        console.log('values', values);

        const response = await uploadZipFileToS3(url as HTMLInputElement, values.version);
        console.log('response', response);

        const newValues = {
          ...values,
          url: response?.src,
        };
        console.log('newValues', newValues);
        setOpen(false);
        await mutate(
          {
            type: 'create',
            resource: 'admin-version-control',
            payload: { data: newValues },
          },
          { returnPromise: true },
        );
        redirect('list', props.basePath);
      } catch (error) {}
    },
    [url, mutate],
  );
  return (
    <div>
      <Create {...props}>
        <SimpleForm redirect="list" save={saveFlyer}>
          <TextInputA
            helperText="Put anything that you can recognize"
            label="Version Number"
            source="version"
            validate={required()}
            resettable
            fullWidth
          />
          <FileInput
            labelSingle="Source Zip File"
            source="url"
            placeholder={<p>Drop your source zip file here</p>}
            onChange={handleFileUpload}
            isRequired
            validate={required()}
          >
            <ImageField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Create>
      <Loader open={open}></Loader>
    </div>
  );
};
export default TCreate;
