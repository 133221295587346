import { Datagrid, TextField, NumberField } from 'react-admin';
import CustomDataGrid from '../Components/CustomDataGrid';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import TextFieldA from '../Components/TextFieldA';

const list = (props: any) => {
  return (
    <ListA {...props}>
      <CustomDataGrid>
        <TextFieldA source="message" />
        <TextFieldA source="optionSelected" />
        <TextFieldA source="id" />
        <NumberField source="cfeedBackId" />
        <DateFieldA showTime source="createdAt" />
        <DateFieldA showTime source="updatedAt" />
      </CustomDataGrid>
    </ListA>
  );
};

export default list;
