import { ImageField, NumberField } from 'react-admin';
import CustomDataGrid from '../Components/CustomDataGrid';
import DateFieldA from '../Components/DateFieldA';
import ListA from '../Components/ListA';
import TextFieldA from '../Components/TextFieldA';

const list = (props: any) => {
  return (
    <ListA {...props}>
      <CustomDataGrid>
        <TextFieldA label="Product" source="product.name" />
        <TextFieldA label="Store" source="store.merchant.name" />
        <ImageField
          label="Product Image"
          source="product.gfImages?.[0]?.src"
          sortable={false}
          title="alt"
        />
        <NumberField source="price" />
        <DateFieldA showTime source="createdAt" />
        <DateFieldA showTime source="updatedAt" />
        <TextFieldA source="id" />
      </CustomDataGrid>
    </ListA>
  );
};

export default list;
