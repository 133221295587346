import {
  DateTimeInput,
  Edit,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import TextInputA from '../Components/TextInputA';
import React, { useState } from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import Loader from '../Components/Loader';

const edit = (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [url, setUrl] = useState('');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [percentage, setPercentage] = useState(0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);
  const onUploadFinish = (img: any) => {
    setUrl(`${window.config.REACT_APP_GOFLYER_S3_END_POINT}/${img.fileKey}`);
    setOpen(false);
  };
  const onUploadProgress = (img: any) => {
    setPercentage(img);
  };

  const preprocess = (file: any, next: any) => {
    setOpen(true);
    next(file);
  };
  return (
    <div>
      <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list">
          <TextInputA resettable disabled source="id" />
          <TextInputA resettable source="alt" />
          {/* <TextInputA resettable source="mediaType" /> */}
          <ReactS3Uploader
            signingUrl={`${window.config.REACT_APP_GOFLYER_API_END_POINT}/s3/sign`}
            signingUrlMethod="GET"
            accept="image/*"
            s3path="flyers"
            autoUpload={true}
            preprocess={preprocess}
            onFinish={onUploadFinish}
            onProgress={onUploadProgress}
          />
          <TextInputA
            resettable
            source="src"
            initialValue={url}
            validate={required()}
            fullWidth
            helperText="Please click the reset button before upload a new file"
          />
          {/* <NumberInputA source="height" />
					<NumberInputA source="width" /> */}
          <DateTimeInput disabled source="createdAt" />
          <DateTimeInput disabled source="updatedAt" />
        </SimpleForm>
      </Edit>
      <Loader open={open} percentage={percentage}></Loader>
    </div>
  );
};

export default edit;
