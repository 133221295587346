import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  RadioButtonGroupInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useMutation,
  useRedirect,
} from 'react-admin';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import DateTimeInputA from '../Components/DateTimeInputA';
import NumberInputA from '../Components/NumberInputA';
import ReferenceInputA from '../Components/ReferenceInputA';
import { parseImageToGfImages } from '../Utils/utils';
import { useCallback } from 'react';

const transform = (data: any) => {
  return {
    ...data,
    gfImages: parseImageToGfImages(data.gfImages),
  };
};

const CreatePromotion = (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutate] = useMutation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const redirect = useRedirect();
  const optionRenderer = (choice: any) => {
    return `${choice.store.merchant.name} ${choice.product.name}`;
  };

  return (
    <Create {...props} transform={transform}>
      <SimpleForm redirect="list">
        <BooleanInput initialValue={false} source="isExclusive" />
        <NumberInputA source="rank" />
        <NumberInputA fullWidth source="quantity" />
        <NumberInputA fullWidth source="price" validate={required()} />
        <TextInput fullWidth source="unit" />
        <DateTimeInputA
          hour={24}
          mintues={0}
          fullWidth
          source="validStartDate"
          validate={required()}
        />
        <DateTimeInputA
          hour={23}
          mintues={59}
          fullWidth
          source="validEndDate"
          validate={required()}
        />
        <DateTimeInputA fullWidth source="publishAt" />
        <NumberInputA fullWidth source="limitQuantityPerFamily" />

        {/* <ArrayInput
					source="gfImages"
					label="Promotional Image(If uploaded, will show this instead of product image)"
				>
					<SimpleFormIterator>
						<ReferenceInputA filterKey="alt" label="Image" source="id" reference="admin-image">
							<AutocompleteInputA
								fullWidth
								resettable
								matchSuggestion={(_filterValue: any) => true}
								optionText={<OptionRenderer />}
								inputText={inputText}
							/>
						</ReferenceInputA>
					</SimpleFormIterator>
				</ArrayInput> */}

        <ReferenceInputA
          filterKey="product.name"
          label="Product"
          source="placement.id"
          reference="admin-placement"
          validate={required()}
        >
          <AutocompleteInputA choices="product" optionText={optionRenderer} />
        </ReferenceInputA>
        <ReferenceInputA
          filterKey="pdf"
          label="Flyer"
          source="flyer.id"
          reference="admin-flyer"
        >
          <AutocompleteInputA resettable optionText="pdf" />
        </ReferenceInputA>
        <ArrayInput source="promotionTag">
          <SimpleFormIterator>
            <ReferenceInputA
              filterKey="name"
              label="Tags"
              source="id"
              reference="admin-promotion-tag"
            >
              <AutocompleteInputA resettable optionText="name" />
            </ReferenceInputA>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          required={true}
          source="showAt"
          choices={[
            { id: 'goflyer', name: 'GoFlyer website & App' },
            { id: 'supermarket', name: 'Store website & App' },
            { id: 'both', name: 'Both GoFlyer & Store (website/App)' },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export default CreatePromotion;
