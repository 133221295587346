import {
  ArrayInput,
  AutocompleteArrayInput,
  Create,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  useMutation,
  useRedirect,
} from 'react-admin';
import React, { useCallback, useState } from 'react';
import ReferenceInputA from '../Components/ReferenceInputA';
import AutocompleteInputA from '../Components/AutocompleteInputA';
import TextInputA from '../Components/TextInputA';
import { OptionRenderer, inputText } from '../Components/OptionRenderer';
import NumberInputA from '../Components/NumberInputA';
import DateTimeInputA from '../Components/DateTimeInputA';
import { parseStoreToGfStores } from '../Utils/utils';
import ReferenceArrayInputA from '../Components/ReferenceArrayInputA';
import { adminApiService } from '../Utils/api';
import { GfImage, GfStore } from '@swagger/typescript-fetch-goflyer';
import Loader from '../Components/Loader';
import Compressor from 'compressorjs';
import ImageInputA from '../Components/ImageInputA';
import { uploadImageToS3 } from '../Utils/image';

const TCreate = (props: any) => {
  const [requiredStore, setRequiredStore] = useState(true);
  const transform = async (data: any) => {
    if (data && data.merchant) {
      const stores =
        (await adminApiService.getManyBaseGfStoreAdminControllerGfStore(
          undefined,
          `{"merchant.id": "${data.merchant}"}`,
        )) as {
          count: number;
          data: GfStore[];
          page: number;
          pageCount: number;
          total: number;
        }; //TODO: temp fix
      if (stores && stores.data.length === 0) {
        alert('No store belong to this merchant');
        return;
      }
      data.stores = stores.data;
    }
    return {
      ...data,
      stores: parseStoreToGfStores(data.stores),
    };
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutate] = useMutation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const redirect = useRedirect();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [url, setUrl] = useState<object[]>([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);

  const handleImageSave = async (event: any) => {
    try {
      setOpen(true);
      const imageFiles = event.filter((value: any) => value?.path);
      const response = imageFiles?.map(async (value: any) => {
        return new Promise(function (resolve, reject) {
          try {
            new Compressor(value, {
              height: 456,
              success: async compressedResult => {
                const responseSmall = await uploadImageToS3(
                  compressedResult,
                  true,
                );
                const responseLarge = await uploadImageToS3(value, false);
                resolve({
                  ...responseLarge,
                  srcSmall: responseSmall?.src,
                });
              },
            });
          } catch (error) {
            reject(error);
          }
        });
      });

      const result: object[] = await Promise.all(response);
      setUrl([...url, ...result]);
      setOpen(false);
    } catch (error) {
      console.log(' error file', error);
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const saveMenu = useCallback(
    async values => {
      try {
        const resullt =
          (await adminApiService.createOneBaseGfImageAdminControllerGfImage(
            url as any,
          )) as GfImage[];

        const storesData = await transform(values);
        const finalData = { ...storesData, gfImages: resullt };

        await mutate(
          {
            type: 'create',
            resource: 'admin-menu',
            payload: { data: finalData },
          },
          { returnPromise: true },
        );
        redirect('list', props.basePath);
      } catch (error) {}
    },
    [url, mutate],
  );
  const startDateValidation = (value: any, allValues: any) => {
    if (!value) {
      return 'The start date is required';
    }

    return undefined;
  };

  const endDateValidation = (value: any, allValues: any) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 3);
    if (!value) {
      return 'The end date is required';
    }
    if (new Date(value).getTime() < new Date().getTime()) {
      return 'End Date should be not less then start date';
    }
    if (
      new Date(value).getDate() === new Date(allValues.validStartDate).getDate()
    ) {
      return ' End Date should be same';
    }
    if (new Date(value).getTime() > currentDate.getTime()) {
      return 'Date should be within a month';
    }
    return undefined;
  };
  const validateStartDate = [required(), startDateValidation];
  const validateEndDate = [required(), endDateValidation];

  const optionRenderer = (store: any) =>
    `${store?.merchant?.name}, ${
      store?.signature !== null ? store.signature : ''
    }`;
  return (
    <div>
      <Create {...props} transform={transform}>
        <SimpleForm redirect="list" save={saveMenu}>
          <TextInputA
            helperText="Put anything that you can recognize"
            label="Name"
            source="name"
            validate={required()}
            resettable
            fullWidth
          />
          <NumberInputA source="rank" />
          <label>如果选择了Merchant， 就不用选store了</label>
          <ReferenceInputA
            fullWidth
            filterKey="name"
            perPage={9999}
            label="Merchant"
            sort={{ field: 'name', order: 'ASC' }}
            source="merchant"
            reference="admin-merchant"
            onChange={(value: any) => {
              if (value === '') {
                setRequiredStore(true);
              } else {
                setRequiredStore(false);
              }
            }}
          >
            <AutocompleteInputA
              resettable
              matchSuggestion={(_filterValue: any) => true}
            />
          </ReferenceInputA>

          {requiredStore && (
            <div>
              <label>如果选择了Store， 就不要选Merchant了</label>
              <ReferenceArrayInputA
                validate={requiredStore ? required() : undefined}
                filterKey="merchant.name"
                perPage={40}
                label="Store"
                sort={{ field: 'merchant.name', order: 'ASC' }}
                source="stores"
                reference="admin-store"
              >
                <AutocompleteArrayInput
                  matchSuggestion={(_filterValue: any) => true}
                  resettable
                  optionText={optionRenderer}
                />
              </ReferenceArrayInputA>
            </div>
          )}

          {/* <DateTimeInputA hour={24} mintues={1} source="validStartDate" validate={validateStartDate} />
					<DateTimeInputA hour={23} mintues={59} source="validEndDate" validate={validateEndDate} /> */}
          <ImageInputA
            source="gfImages"
            accept="image/*"
            multiple={true}
            onChange={handleImageSave}
            validate={required()}
          >
            <ImageField source="src" title="title" />
          </ImageInputA>

          <ArrayInput source="menuTag" validate={required()}>
            <SimpleFormIterator>
              <ReferenceInputA
                filterKey="name"
                label="Tags"
                source="id"
                reference="admin-menu-tag"
              >
                <AutocompleteInputA resettable optionText="name" />
              </ReferenceInputA>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
      <Loader open={open}></Loader>
    </div>
  );
};
export default TCreate;
