import { GfImage, GfStore } from '@swagger/typescript-fetch-goflyer';
import { useCallback, useState } from 'react';
import {
  Create,
  SimpleForm,
  required,
  ImageField,
  useMutation,
  useRedirect,
  useNotify,
} from 'react-admin';
import ImageInputA from '../Components/ImageInputA';
import NumberInputA from '../Components/NumberInputA';
import TextInputA from '../Components/TextInputA';
import { adminApiService } from '../Utils/api';
import { uploadImageToS3 } from '../Utils/image';

const CreateMerchant = (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [smallImgUrl, setSmallImgUrl] = useState<object>();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [largeImgUrl, setLargeImgUrl] = useState<object>();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [merchantImageLogoUrl, setMerchantImageLogoUrl] = useState<object>();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutate] = useMutation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const redirect = useRedirect();
  const handleImageSave = async (event: any) => {
    console.log(`imag save`);

    try {
      setOpen(true);
      const response = (await uploadImageToS3(event, false)) as Object;
      setSmallImgUrl(response);
      setOpen(false);
    } catch (error) {
      console.log(' error file', error);
    }
  };
  const handleMerchantLogoeSave = async (event: any) => {
    console.log(`handleMerchantLogoeSave`);

    try {
      setOpen(true);
      const response = (await uploadImageToS3(event, false)) as Object;
      setMerchantImageLogoUrl(response);
      setOpen(false);
    } catch (error) {
      console.log(' error file', error);
    }
  };
  const handleLargeImageSave = async (event: any) => {
    try {
      setOpen(true);
      const response = (await uploadImageToS3(event, false)) as Object;
      setLargeImgUrl(response);
      setOpen(false);
    } catch (error) {
      console.log(' error file', error);
    }
  };

  interface ImageUpload extends GfImage {
    id: string;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const saveFlyer = useCallback(
    async values => {
      try {
        if (largeImgUrl && smallImgUrl && merchantImageLogoUrl) {
          const merchantLogo: ImageUpload[] =
            (await adminApiService.createOneBaseGfImageAdminControllerGfImage([
              merchantImageLogoUrl,
            ] as any)) as ImageUpload[];
          const largeImg: ImageUpload[] =
            (await adminApiService.createOneBaseGfImageAdminControllerGfImage([
              largeImgUrl,
            ] as any)) as ImageUpload[];
          const smallImg: ImageUpload[] =
            (await adminApiService.createOneBaseGfImageAdminControllerGfImage([
              smallImgUrl,
            ] as any)) as ImageUpload[];
          const finalData = {
            ...values,
            merchantMapLargeLogo: { id: largeImg[0].id },
            merchantMapSmallLogo: { id: smallImg[0].id } as GfImage,
            merchantLogo: { id: merchantLogo[0].id } as GfImage,
          };
          await mutate(
            {
              type: 'create',
              resource: 'admin-merchant',
              payload: { data: finalData },
            },
            { returnPromise: true },
          );
          redirect('list', props.basePath);
        } else {
          notify(`Please add Merchant Map small and large image`);
        }
      } catch (error) {}
    },
    [largeImgUrl, smallImgUrl, merchantImageLogoUrl, mutate],
  );
  return (
    <Create {...props}>
      <SimpleForm redirect="list" save={saveFlyer}>
        <TextInputA resettable source="name" validate={required()} />
        <TextInputA resettable source="nameChinese" validate={required()} />
        <TextInputA resettable source="domain" validate={required()} />
        <ImageInputA
          label="Merchant Logo"
          source="merchantLogo.id"
          reference="admin-image"
          accept="image/*"
          onChange={handleMerchantLogoeSave}
          validate={required()}
        >
          <ImageField source="src" title="title" />
        </ImageInputA>
        <ImageInputA
          label="Merchant Map Small Logo"
          source="merchantMapSmallLogo.id"
          reference="admin-image"
          accept="image/*"
          onChange={handleImageSave}
          validate={required()}
        >
          <ImageField data-testid="title" source="src" title="title" />
        </ImageInputA>
        <ImageInputA
          label="Merchant Map Large Logo"
          source="merchantMapLargeLogo.id"
          reference="admin-image"
          accept="image/*"
          onChange={handleLargeImageSave}
          validate={required()}
        >
          <ImageField source="src" title="title" />
        </ImageInputA>
        <TextInputA resettable source="address" />
        <TextInputA resettable source="city" />
        <TextInputA resettable source="country" />
        <TextInputA resettable source="province" />
        <TextInputA resettable source="street" />
        <TextInputA resettable source="description" />
        <TextInputA resettable source="descriptionChinese" />
        <TextInputA resettable source="zip" />

        <TextInputA source="phone" />
        <NumberInputA source="productsCount" />
        <TextInputA resettable source="refundPolicy" />
      </SimpleForm>
    </Create>
  );
};

export default CreateMerchant;
